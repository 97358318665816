<template>
    <div class="box">
        <ul>
            <li @click="navBar(1, '/centre/order')" :class="{ 'active': $route.path == '/centre/order' }">
                我的订单
            </li>
            <li @click="navBar(2, '/centre/member')" :class="{ 'active': $route.path == '/centre/member' }">
                会员中心
            </li>
            <li @click="navBar(3, '/centre/discount')" :class="{ 'active': $route.path == '/centre/discount' }">
                优惠券
            </li>
            <li @click="navBar(4, '/centre/evaluate')" :class="{ 'active': $route.path == '/centre/evaluate' }">
                客人评价
            </li>
            <li @click="navBar(5, '/centre/promise')" :class="{ 'active': $route.path == '/centre/promise' }">
                平台承诺
            </li>
            <li @click="navBar(6, '/centre/about')" :class="{ 'active': $route.path == '/centre/about' }">
                关于平台
            </li>
            <li @click="navBar(7, '/centre/set')" :class="{ 'active': $route.path == '/centre/set' }">
                设置
            </li>
            <li @click="navBar(8, '/centre/complaint')" :class="{ 'active': $route.path == '/centre/complaint' }">
                投诉建议
            </li>
			<li @click="navBar(9, '/centre/message')" :class="{ 'active': $route.path == '/centre/message' }">
			    系统消息
			</li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: 1,
        }
    },
    mounted() {
        this.active = sessionStorage.getItem('ACTIVE_MENU_PERSON');
    },
    methods: {
        navBar(id, path) {
            this.active = id;
            sessionStorage.setItem('ACTIVE_MENU_PERSON', id);
            this.$router.push(path, () => { }, () => { })
        }
    }
}
</script>

<style scoped lang="scss">
.box {
    width: 148px;
    background: #FFFFFF;
    height: 516px;

    ul {
        padding: 14px 0;
    }

    li {
        padding-left: 30px;
        height: 55px;
        line-height: 55px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;

        .router-link-exact-active {
            color: #0D5E6B;
        }

        a {
            color: #000;
        }


    }

    .active {
        font-weight: 400;
        background: #0D5E6B08;
        border-left: 4px solid #09525e;
        padding-left: 26px;

    }
}
</style>