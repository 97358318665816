<template>
	<div class="centers">
		<div class="box">
			<div class="title">类型</div>
			<div class="tabbar">
				<div class="tab" :class="act == item.id ? 'active' : ''" v-for="item in  tabList " :key="item.id"
					@click="Tabchange(item.id)">{{ item.title }}</div>
			</div>
			<div class="title">出发地</div>
			<div class="flexs1">
				<div class="inputs">
					<el-select v-model="info.cfGuo_id" placeholder="请选择国家" @change="getCard($event, 'cfGuo')">
						<el-option v-for="item in cfGuos" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="inputs">
					<el-select v-model="info.cfSheng_id" placeholder="请选择省份" @change="getCard($event, 'cfSheng')">
						<el-option v-for="item in cfShengs" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="inputs">
					<el-select v-model="info.cfShi_id" placeholder="请选择城市" @change="getCard($event, 'cfShi')">
						<el-option v-for="item in cfShis" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<address-frame @inputValue="inputValueStart" placeholder="出发地详细地址"></address-frame>
				<!-- <div class="input-box" style="width: 20%;margin-bottom: 10px;">
				    <el-input v-model="info.start" placeholder="请输入出发地详细地址"></el-input>
				</div> -->

			</div>

			<div class="title">到达地</div>
			<div class="flexs1">
				<div class="inputs">
					<el-select v-model="info.mddGuo_id" placeholder="请选择国家" @change="getCard($event, 'mddGuo')">
						<el-option v-for="item in mddGuos" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="inputs">
					<el-select v-model="info.mddSheng_id" placeholder="请选择省份" @change="getCard($event, 'mddSheng')">
						<el-option v-for="item in mddShengs" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="inputs">
					<el-select v-model="info.mddShi_id" placeholder="请选择城市" @change="getCard($event, 'mddShi')">
						<el-option v-for="item in mddShis" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<address-frame @inputValue="inputValueEnd" placeholder="到达地详细地址"></address-frame>
				<!-- <div class="input-box" style="width: 20%;margin-bottom: 10px;">
				    <el-input v-model="info.end" placeholder="请输入到达地地址"></el-input>
				</div> -->
			</div>
			<div class="title">航班信息</div>
			<div class="flexs1 new_box">
				<el-input placeholder="请输入航班号" v-model="info.airplaneNo"></el-input>
				<div class="inputs" style="width: 94%;padding-bottom: 0;padding-top: 0;margin-left: 50px;">
					<el-date-picker type="datetime" placeholder="选择日期时间" default-time="12:00:00" @change="getTime"
						value-format="yyyy-MM-dd HH:mm:ss" v-model="info.Stime">
					</el-date-picker>
				</div>
			</div>

			<div class="title">车型</div>
			<div class="flexs1">
				<div class="inputs">
					<el-select v-model="info.value" placeholder="请选择" @change="getCard($event, 'car')">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="flexs1">
					<div class="flexs1" style="cursor: pointer" @click="chooseCar()">
						<div>车型介绍</div>
						<img src="../../../public/img/icon_shuaxin.png" class="jie_img" />
					</div>
					<div class="flexs1" style="margin-left: 16px;cursor: pointer" @click="chooseCar1()">
						<div>行李示例图</div>
						<img src="../../../public/img/icon_shuaxin.png" class="jie_img" />
					</div>
				</div>
			</div>
			<div class="tipes" v-if="isShowcar">该车系共{{ changeCarinfo.passengerCapacity }}个座位，最多可携带{{
				changeCarinfo.luggageBearCapacity }}kg行李</div>
			<div class="title">平台服务</div>
			<div style="display: flex; align-items: center; margin-top: 8px; gap: 18px;">
				<el-checkbox label="举牌接机" v-model="Raising"></el-checkbox>
				<el-checkbox v-model="theBaby">
					<div class="child">
						<div>宝宝座椅</div>
					</div>
				</el-checkbox>
				<el-input-number v-model="num" v-if="theBaby == true" :min="1" size="small"></el-input-number>
				<el-checkbox label="接机服务" v-model="songServe"></el-checkbox>
			</div>
		</div>

		<!-- 行李示例图弹框 -->
		<el-dialog :visible.sync="carTan1" width="30%" top="35vh">
			<div class="title flexs" style="font-size: 22px;">行李示例图:</div>
			<div class="flexs" style="width: 100%;">
				<div v-if="info.value != ''">
					<div style="width: 100%;height: 300px;" v-for="(item, index) in changeCarinfo.picPath" :key="index">
						<img :src="item" class="car_img" />
					</div>
				</div>
				<div v-else style="margin-top: 20px;">请选择车型</div>
			</div>
		</el-dialog>
		<!-- 车型介绍弹框 -->
		<el-dialog :visible.sync="carTan" width="30%" top="35vh">
			<div class="title flexs" style="font-size: 22px;">车型介绍:</div>
			<div class="" style="width: 100%;">
				<div v-if="info.value != ''">
					<div style="margin: 20px 0;min-height: 300px;" v-html="changeCarinfo.introduce || '无介绍'"></div>
				</div>
				<div v-else style="margin-top: 20px;text-align: center;">请选择车型</div>
			</div>
		</el-dialog>
		<!-- 价格弹框 -->
		<el-dialog :visible.sync="dialogVisible" width="30%" top="35vh">
			<div class="title" style="font-size: 22px;">服务价格:</div>
			<div class="tcBOX">
				<div class="price">￥{{ price }}</div>
				<div class="flexs3" style="width: 100%;flex-wrap: wrap;margin: 15px 0;line-height: 31px;">
					<div class="flexs1" style="margin-right: 24px">
						<div class="jia_text">价格：</div>
						<div>￥{{ price }}</div>
					</div>
					<div class="flexs1" style="margin-right: 24px">
						<div class="jia_text">距离：</div>
						<div>{{ juli }}km</div>
					</div>
					<div class="flexs1" style="margin-right: 24px">
						<div class="jia_text">用时：</div>
						<div>{{ hour }}H</div>
					</div>
					<div class="flexs1" style="margin-right: 24px">
						<div class="jia_text">车型：</div>
						<div>{{ changeCarinfo.carTypeName }}</div>
					</div>
					<div class="flexs1" style="margin-right: 24px">
						<div class="jia_text">过路桥费：</div>
						<div>￥{{ cost }}</div>
					</div>
				</div>
				<div class="btns">
					<button class="submit-btn" @click="onOrder">立即出发</button>
				</div>
			</div>
		</el-dialog>
		<div class="box">
			<div class="btns">
				<button class="submit-btn" @click="onCalculate" :disabled="del">计算价格</button>
			</div>
		</div>
	</div>
</template>

<script>
import {
	Message
} from 'element-ui'
import {
	listAllCarConfig,
	initOrderOneTwoMoney,
	getCpc
} from '@/api/order'
import addressFrame from '@/components/address-frame/address-frame.vue'
export default {
	name: 'airport',
	components: {
		addressFrame
	},
	data() {
		return {
			carTan: false,
			carTan1: false,

			tabList: [{
				id: 0,
				title: '去机场接您'
			},
			{
				id: 1,
				title: '送您去机场'
			}
			],
			act: 0,
			info: {
				start: '',
				end: '',
				airplaneNo: '', //航班号
				value: '', //选中的车形id
				Stime: '', //出发时间
			},

			options: [],
			isShowcar: false,
			dialogVisible: false,

			Raising: false, //举牌接机自定义状态
			theBaby: false, //宝宝座椅自定义状态
			num: 1, //宝宝座椅数量自定义
			songServe: false,//接机服务自定义状态

			theRaising: 1, //是否需要接机服务
			theBabyNum: 0, //宝宝座椅数量
			songRaising: 1, //是否需要接机服务

			changeCarinfo: {}, //选中的车型详情,
			cfGuos: [],
			cfShengs: [],
			cfShis: [],
			mddGuos: [],
			mddShengs: [],
			mddShis: [],

			cfGuoinfo: {},
			cfShenginfo: {},
			cfShiinfo: {},
			mddGuoinfo: {},
			mddShenginfo: {},
			mddShiinfo: {},

			//计算价格参数
			price: "",
			juli: "",
			hour: "",
			cost: "",

		}
	},
	computed: {
		del() {
			for (let key in this.info) {
				if (this.info[key] === '') {
					return true;
				}
			}
			return false;
		}
	},
	created() {
		getCpc({
			pid: 0
		}).then(res => {
			this.cfGuos = res.data
			this.mddGuos = res.data
		})
		listAllCarConfig().then(res => {
			res.data.forEach(i => {
				i.label = i.carTypeName
				i.value = i.id
				i.introduce = i.introduce
				i.picPath = [i.picPath]
			})
			this.options = res.data
		})
	},
	methods: {
		//赋值出发地、结束地地址
		inputValueStart(e) {
			this.info.start = e;
		},
		inputValueEnd(e) {
			this.info.end = e;
		},
		chooseCar() {
			this.carTan = !this.carTan
		},
		chooseCar1() {
			this.carTan1 = !this.carTan1
		},
		Tabchange(index) {
			this.act = index

		},
		getCard(e, type) {
			if (type == 'car') {
				//选择车型
				this.changeCarinfo = this.options.filter(i => i.id == e)[0]
				this.isShowcar = true
			} else if (type == 'cfGuo') {
				//选择出发国
				const cfGuos = Array.isArray(this.cfGuos) ? this.cfGuos : Object.values(this.cfGuos);

				this.cfGuoinfo = cfGuos.filter(i => i.id == e)[0]
				getCpc({
					pid: e
				}).then(res => {
					this.cfShengs = res.data
				})
			} else if (type == 'cfSheng') {
				//选择出发省
				const cfShengs = Array.isArray(this.cfShengs) ? this.cfShengs : Object.values(this.cfShengs);

				this.cfShenginfo = cfShengs.filter(i => i.id == e)[0]
				getCpc({
					pid: e
				}).then(res => {
					this.cfShis = res.data
				})
			} else if (type == 'cfShi') {
				//选择出发市
				const cfShis = Array.isArray(this.cfShis) ? this.cfShis : Object.values(this.cfShis);

				this.cfShiinfo = cfShis.filter(i => i.id == e)[0]
			} else if (type == 'mddGuo') {
				//选择目的地国家
				const mddGuos = Array.isArray(this.mddGuos) ? this.mddGuos : Object.values(this.mddGuos);

				this.mddGuoinfo = mddGuos.filter(i => i.id == e)[0]
				getCpc({
					pid: e
				}).then(res => {
					this.mddShengs = res.data
				})
			} else if (type == 'mddSheng') {
				//选择目的地省份
				const mddShengs = Array.isArray(this.mddShengs) ? this.mddShengs : Object.values(this.mddShengs);

				this.mddShenginfo = mddShengs.filter(i => i.id == e)[0]
				getCpc({
					pid: e
				}).then(res => {
					this.mddShis = res.data
				})
			} else if (type == 'mddShi') {
				//选择目的地城市
				const mddShis = Array.isArray(this.mddShis) ? this.mddShis : Object.values(this.mddShis);

				this.mddShiinfo = mddShis.filter(i => i.id == e)[0]
			}

		},
		getTime() { },
		//计算价格
		onCalculate() {
			let theBaby
			let theRaising
			let songRaising
			if (Object.keys(this.cfShiinfo).length == 0) {
				this.$message({
					message: '请选择出发地城市',
					type: 'warning'
				});
				return
			}
			if (Object.keys(this.mddShiinfo).length == 0) {
				this.$message({
					message: '请选择到达地城市',
					type: 'warning'
				});
				return
			}
			//举牌接机
			if (this.Raising) {
				theRaising = 2 //1不需要 2需要
				this.theRaising = 2
			} else {
				theRaising = 1
				this.theRaising = 1
			}
			//宝宝座椅
			if (this.theBaby) {
				theBaby = this.num
				this.theBabyNum = this.num
			} else {
				theBaby = 0
				this.theBabyNum = 0
			}
			//接机服务
			if (this.songServe) {
				songRaising = 2 //1不需要 2需要
				this.songRaising = 2
			} else {
				songRaising = 1
				this.songRaising = 1
			}
			const loading = this.$loading({
				lock: true,
				text: '价格计算中...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.8)'
			});

			initOrderOneTwoMoney({
				setOutAddress: this.info.start,
				arriveOutAddress: this.info.end,
				appointmentTime: this.info.Stime,
				cardId: this.info.value,
				theRaising,
				theBaby,
				songRaising,
				cfGuo: this.cfGuoinfo.name,
				cfSheng: this.cfShenginfo.name,
				cfShi: this.cfShiinfo.name,
				mddGuo: this.mddGuoinfo.name,
				mddSheng: this.mddShenginfo.name,
				mddShi: this.mddShiinfo.name,
				cfGuoId: this.cfGuoinfo.id,
				cfShengId: this.cfShenginfo.id,
				cfShiId: this.cfShiinfo.id,
				mddGuoId: this.mddGuoinfo.id,
				mddShengId: this.mddShenginfo.id,
				mddShiId: this.mddShiinfo.id,
			}).then(res => {
				if (res.code == 200) {
					this.price = res.data.orderAllMoney
					this.juli = res.data.juli
					this.hour = res.data.hour
					this.cost = res.data.cost
					this.dialogVisible = true
				} else {
					Message({
						type: 'error',
						message: res.msg
					})
				}
				loading.close();
			})

			//测试代码
			// setTimeout(() => {
			//   loading.close();
			//   this.price = 892
			//   this.dialogVisible = true
			// }, 1000);

		},
		onOrder() {
			let order = {
				orderSource: 'PC',
				orderTypeCode: 'PICKUP_SERVICES',
				setOutAddress: this.info.start,
				arriveOutAddress: this.info.end,
				appointmentTime: this.info.Stime,
				cardId: this.info.value,
				allMoney: this.price,
				theRaising: this.theRaising,
				theBaby: this.theBabyNum,
				songRaising: this.songRaising,
				aircraft: this.act, //0接机 1送机
				airplaneNo: this.info.airplaneNo

			}
			this.$router.push({
				name: 'qorder',
				query: {
					obj: encodeURIComponent(JSON.stringify(order)),
					type: 3,
				}
			})
		}

	}
}
</script>

<style lang="scss" scoped>
.car_img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	margin: 0 auto;
	margin-top: 20px
}

.jia_text {
	color: #999999;
}

.jie_img {
	width: 14px;
	height: 14px;
	margin-left: 8px;
}

.new_box {
	width: 100%;
}

.new_box .el-input {
	width: 34%;
}

.flexs {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flexs1 {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.flexs2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flexs3 {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.flexs4 {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	align-self: flex-end;
}

.tipes {
	margin: 0 0 14px 0;
	font-size: 14px;
	color: #FE4E00;
}

.box {
	margin-top: 20px;
	background: #fff;
	width: 100%;
	padding: 24px;
	border-radius: 12px;

	::v-deep .el-input__inner {
		background-color: #F3F3F3;
	}

	::v-deep .el-checkbox__label {
		font-size: 18px;
	}

	.tabbar {
		display: flex;
		padding: 10px 0 20px 0;


		.tab {
			width: 254px;
			height: 40px;
			background: #FFFFFF;
			border: 1px solid #0D5E6B;
			line-height: 40px;
			text-align: center;
			font-size: 18px;
			color: #0D5E6B;
			cursor: pointer;
			user-select: none;

		}

		.active {
			background: #0D5E6B;
			color: #F3F3F3;
		}
	}

	.title {
		font-size: 24px;
		line-height: 39px;
	}

	.input-box {
		padding: 16px 0 20px 0;

	}

	.inputs {
		width: 23%;
		display: flex;
		justify-content: space-between;
		padding: 6px 0 20px 0;

	}



	.btns {
		width: 300px;
	}


}

::v-deep .el-checkbox__label {
	font-size: 20px !important;
}

.tcBOX {
	text-align: center;

	.price {
		color: red;
		font-size: 30px;
		line-height: 60px;
	}

	.btns {
		width: 60%;
		margin: 0 auto;
	}
}
</style>