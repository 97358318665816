import { render, staticRenderFns } from "./carDetails.vue?vue&type=template&id=173a6419&scoped=true&"
import script from "./carDetails.vue?vue&type=script&lang=js&"
export * from "./carDetails.vue?vue&type=script&lang=js&"
import style0 from "./carDetails.vue?vue&type=style&index=0&id=173a6419&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "173a6419",
  null
  
)

export default component.exports