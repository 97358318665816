<template>
	<div class="head">
		<div class="left">
			<!-- <div class="kaui">{{$route.meta.title}}</div> -->
			<div class="kaui" @click="onHome">远山旅游</div>
		</div>
		<div class="right">
			<div class="navlist">
				<router-link to="/tailored">
					<div class="item" @click="navBar(1)" :class="{ 'active': $route.path == '/tailored' }">专车服务</div>
				</router-link>
				<router-link to="">
					<div class="item" @click="goLink()">汽车租赁</div>
				</router-link>
				<!-- <router-link to="/carRental">
					<div class="item" @click="navBar(2)" :class="{ 'active': $route.path == '/carRental' }">汽车租赁</div>
				</router-link> -->
				<router-link to="/travel">
					<div class="item" @click="navBar(3)" :class="{ 'active': $route.path == '/travel' }">旅游产品</div>
				</router-link>
				<router-link to="/airport">
					<div class="item" @click="navBar(4)" :class="{ 'active': $route.path == '/airport' }">接送机</div>
				</router-link>
				<router-link to="/chartered">
					<div class="item" @click="navBar(4)" :class="{ 'active': $route.path == '/chartered' }">单次包车</div>
				</router-link>
				<router-link to="/download">
					<div class="item" @click="navBar(4)" :class="{ 'active': $route.path == '/download' }">下载APP</div>
				</router-link>
				<div class="item" v-if="isLogin == false" @click="$store.commit('SHOWLOGIN')">登录</div>
				<div class="item" v-if="isLogin == false" @click="$store.commit('SHOWREGISTER')">注册</div>
				<div class="item" v-if="isLogin">{{ userInfo.mobile.slice(0, 3) }}*****{{ userInfo.mobile.slice(-4) }}
				</div>
				<router-link to="/centre/order" v-if="isLogin">
					<div class="item" @click="navBar(7)" :class="{ 'active': $route.path == '/centre/order' }">个人中心
					</div>
				</router-link>
				<div class="item" v-if="isLogin" @click="isEdit = true">退出</div>
			</div>
		</div>
		<div class="tips">
			<el-dialog title="欢迎登录" :visible.sync="$store.state.showLogin" width="440px" center>
				<div class="info" style="position: relative;">
					<div class="flexs2">
						<div class="flexs1" style="height:45px;margin-top: 22px;" @click="chooseXina()">
							<div>+{{ quhao }}</div>
							<img src="../../public/img/icon_xl.png" class="la_img" />
						</div>
						<div class="input-box_s">
							<el-input placeholder="请输入手机号" v-model="login.phone"></el-input>
						</div>
					</div>

					<div class="input-box">
						<el-input placeholder="请输入密码" show-password v-model="login.password"></el-input>
					</div>

					<!-- 手机号选择 -->
					<div class="xuan_box flexs" v-if="isXian == true">
						<div style="line-height: 66rpx;">
							<div @click="choosequhao(86)">+86</div>
							<div @click="choosequhao(12)">+12</div>
							<div @click="choosequhao(14)">+14</div>
						</div>
					</div>
				</div>
				<span slot="footer">
					<div class="bottom">
						<div class="tit">还没有账号?<span @click="onRegisters">立即注册</span></div>
						<div class="tits" @click="onforgetPassword">忘记密码</div>
					</div>
					<div class="btn">
						<button class="submit-btn" :disabled="disabledLogin" @click="goLogin">登录</button>
					</div>
				</span>
			</el-dialog>
		</div>
		<div class="tips">
			<el-dialog title="欢迎加入" :visible.sync="$store.state.showRegister" width="440px" center>
				<div class="info" style="position: relative;">
					使用您的手机号注册
					<div class="flexs2">
						<div class="flexs1" style="height:45px;margin-top: 22px;" @click="chooseXina1()">
							<div>+{{ quhao }}</div>
							<img src="../../public/img/icon_xl.png" class="la_img" />
						</div>
						<div class="input-box_s">
							<el-input placeholder="请输入手机号" v-model="resgister.phone"></el-input>
						</div>
					</div>
					<!-- 手机号选择 -->
					<div class="xuan_box_one flexs" v-if="isXian1 == true">
						<div style="line-height: 66rpx;">
							<div @click="choosequhao1(86)">+86</div>
							<div @click="choosequhao1(12)">+12</div>
							<div @click="choosequhao1(14)">+14</div>
						</div>
					</div>
					<!-- <div class="input-box">
						<el-input placeholder="请输入手机号" v-model="resgister.phone"></el-input>
					</div> -->
					<div class="input-box input-code">
						<div class="inputc">
							<el-input placeholder="请输入验证码" v-model="resgister.code"></el-input>
						</div>
						<div class="code" v-if="miao1" @click="getCode1">获取验证码</div>
						<div class="code" v-else>{{ time1 }}s后重发</div>
					</div>
					<div class="input-box">
						<el-input placeholder="请设置您的密码" show-password v-model="resgister.password"></el-input>
					</div>
					<div class="input-box">
						<el-input placeholder="请再次输入您设置的密码" show-password v-model="resgister.newpassword"></el-input>
					</div>
					<div class="input-box">
						<el-input placeholder="请输入您的邮箱" v-model="resgister.email"></el-input>
					</div>
				</div>
				<span slot="footer">
					<div class="title">
						注册即代表您同意平台<span @click="onAgreement(1)">《服务协议》</span>与<span @click="onAgreement(2)">《隐私政策》</span>
					</div>
					<div class="btn">
						<button class="submit-btn" :disabled="disabledResgister" @click="goResgister">注册</button>
					</div>
					<div class="bottom">
						<div class="tit">有远山旅游账号？<span @click="onLogins">立即登录</span></div>
					</div>
				</span>
			</el-dialog>
		</div>
		<div class="tips">
			<el-dialog title="重置密码" :visible.sync="$store.state.showPass" width="440px" center>
				<div class="info" style="position: relative;">
					使用您的手机号以重置密码
					<div class="flexs2">
						<div class="flexs1" style="height:45px;margin-top: 22px;" @click="chooseXina2()">
							<div>+{{ quhao }}</div>
							<img src="../../public/img/icon_xl.png" class="la_img" />
						</div>
						<div class="input-box_s">
							<el-input placeholder="请输入手机号" v-model="forgetPass.phone"></el-input>
						</div>
					</div>
					<!-- <div class="input-box">
						<el-input placeholder="请输入手机号" v-model="forgetPass.phone"></el-input>
					</div> -->
					<!-- 手机号选择 -->
					<div class="xuan_box_one flexs" v-if="isXian2 == true">
						<div style="line-height: 66rpx;">
							<div @click="choosequhao2(86)">+86</div>
							<div @click="choosequhao2(12)">+12</div>
							<div @click="choosequhao2(14)">+14</div>
						</div>
					</div>
					<div class="input-box input-code">
						<div class="inputc">
							<el-input placeholder="请输入验证码" v-model="forgetPass.code"></el-input>
						</div>
						<div class="code" v-if="miao2" @click="getCode2">获取验证码</div>
						<div class="code" v-else>{{ time2 }}s后重发</div>
					</div>
					<div class="input-box">
						<el-input placeholder="请设置您的密码" show-password v-model="forgetPass.password"></el-input>
					</div>
					<div class="input-box">
						<el-input placeholder="请再次输入您设置的密码" show-password v-model="forgetPass.newpassword"></el-input>
					</div>
				</div>
				<span slot="footer">
					<div class="btn">
						<button class="submit-btn" :disabled="disabledResetPass" @click="goResetPass">重置密码</button>
					</div>
				</span>
			</el-dialog>
		</div>
		<el-dialog title="提示" :visible.sync="isEdit" width="400px" center>
			<span style="font-size: 18px; text-align: center;!important">您确定要退出该账号吗？</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="onExit">确 定</el-button>
				<el-button @click="isEdit = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {
	appLogin,
	appRegister,
	sendAccountCode,
	forgotPassword,
	passwordBlackCode,
	platFormTextType
} from '@/api/loginAbout'
import {
	getHighImportInfo
} from '@/api/mine'
import {
	Message
} from 'element-ui'
export default {
	data() {
		return {
			isXian: false,
			isXian1: false,
			isXian2: false,
			userInfo: {
				mobile: ''
			},
			active: null,
			login: {
				phone: "",
				password: ""
			},
			resgister: {
				phone: "",
				code: "",
				password: "",
				newpassword: "",
				email: ""
			},
			forgetPass: {
				phone: "",
				code: "",
				password: "",
				newpassword: "",
			},
			miao1: true, //发送验证码1状态
			time1: 60,
			miao2: true, //发送验证码2状态
			time2: 60,
			isLogin: false,
			isEdit: false,
			quhao: 86

		}
	},
	created() {
		if (localStorage.getItem('token')) {
			getHighImportInfo().then(res => {
				this.userInfo = res.data
			})
		}
	},
	computed: {
		disabledLogin() {
			for (let key in this.login) {
				if (this.login[key] === '') {
					return true;
				}
			}
			return false;
		},
		disabledResgister() {
			for (let key in this.resgister) {
				if (this.resgister[key] === '') {
					return true;
				}
			}
			return false;
		},
		disabledResetPass() {
			for (let key in this.forgetPass) {
				if (this.forgetPass[key] === '') {
					return true;
				}
			}
			return false;
		}
	},
	mounted() {
		console.log(localStorage.getItem('token'), '信息')
		if (localStorage.getItem('token')) {
			this.isLogin = true

		}
		this.active = sessionStorage.getItem('ACTIVE_MENU');
	},
	methods: {
		choosequhao2(num) {
			this.quhao = num
			this.chooseXina2()
		},
		choosequhao(num) {
			this.quhao = num
			this.chooseXina()
		},
		choosequhao1(num) {
			this.quhao = num
			this.chooseXina1()
		},
		chooseXina() {
			this.isXian = !this.isXian
		},
		chooseXina1() {
			this.isXian1 = !this.isXian1
		},
		chooseXina2() {
			this.isXian2 = !this.isXian2
		},
		//获取验证码1,注册
		getCode1() {
			if (/^1[3-9]{1}[0-9]{9}$/.test(this.resgister.phone)) {
				sendAccountCode({
					mobile: this.resgister.phone,
					areaCode: this.quhao
				}).then(res => {
					if (res.code === 200) {
						this.miao1 = false
						let timer = setInterval(() => {
							if (this.time1 > 0) {
								this.time1--
							} else {
								this.miao1 = true
								this.time1 = 60
								clearInterval(timer)
							}
						}, 1000)
					} else {
						Message({
							type: 'error',
							message: res.msg
						})
					}
				})
			} else {
				Message({
					type: 'warning',
					message: '请输入正确的手机号码'
				})
			}
		},
		//获取验证码2，重置密码
		getCode2() {
			if (/^1[3-9]{1}[0-9]{9}$/.test(this.forgetPass.phone)) {
				passwordBlackCode({
					mobile: this.forgetPass.phone,
					areaCode: this.quhao
				}).then(res => {
					if (res.code === 200) {
						this.miao2 = false
						let timer = setInterval(() => {
							if (this.time2 > 0) {
								this.time2--
							} else {
								this.miao2 = true
								this.time2 = 60
								clearInterval(timer)
							}
						}, 1000)
					} else {
						Message({
							type: 'error',
							message: res.msg
						})
					}
				})

			} else {
				Message({
					type: 'warning',
					message: '请输入正确的手机号码'
				})
			}
		},
		//从登录跳转到注册
		onRegisters() {
			this.$store.commit('NOSHOWLOGIN')
			this.$store.commit('SHOWREGISTER')
		},
		//从登录跳转到忘记密码
		onforgetPassword() {
			this.$store.commit('NOSHOWLOGIN')
			this.$store.commit('SHOWPASS')
		},
		//从注册跳转到登录
		onLogins() {
			this.$store.commit('NOSHOWREGISTER')
			this.$store.commit('SHOWLOGIN')
		},
		//页面导航跳转
		navBar(id) {
			this.active = id;
			sessionStorage.setItem('ACTIVE_MENU', id);
			// console.log(this.$route.path)
		},
		//去用户协议
		onAgreement(type) {
			this.$store.commit('NOSHOWREGISTER')
			this.$router.push({
				path: 'agreement',
				query: {
					type: type
				}
			})
		},
		//去汽车租赁
		goLink() {
			platFormTextType('COLLABORATIONLINK').then(res => {
            let url = res.data.context
						window.open(url)
      })
		},
		//登录
		goLogin() {
			appLogin({
				mobile: this.login.phone,
				password: this.login.password,
			}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					localStorage.setItem('token', res.data)
					this.isLogin = true
					this.$store.commit('NOSHOWLOGIN')
					Message({
						type: 'success',
						message: '登录成功'
					})
					this.login.phone = ""
					this.login.password = ""
					getHighImportInfo().then(res => {
						this.userInfo = res.data
					})
				} else {
					Message({
						type: 'error',
						message: res.msg
					})
				}

			})

		},
		//注册
		goResgister() {
			appRegister({
				mobile: this.resgister.phone,
				yzParm: this.resgister.code,
				password: this.resgister.password,
				surePassword: this.resgister.newpassword,
				email: this.resgister.email
			}).then(res => {
				if (res.code === 200) {
					this.$store.commit('NOSHOWREGISTER')
					this.resgister = {
						phone: "",
						code: "",
						password: "",
						newpassword: "",
						email: ""
					}
					Message({
						type: 'success',
						message: '注册成功，请登录！'
					})
					setTimeout(() => {
						this.$store.commit('SHOWLOGIN')
					}, 500);
				} else {
					Message({
						type: 'error',
						message: res.msg
					})
				}
			})
		},
		//重置密码
		goResetPass() {
			forgotPassword({
				password: this.forgetPass.password,
				yzParm: this.forgetPass.code,
				surePassword: this.forgetPass.newpassword,
				mobile: this.forgetPass.phone
			}).then(res => {
				if (res.code === 200) {
					this.$store.commit('NOSHOWPASS')
					this.forgetPass = {
						phone: "",
						code: "",
						password: "",
						newpassword: "",
					}
					Message({
						type: 'success',
						message: '密码重置成功，请登录！'
					})
					setTimeout(() => {
						this.$store.commit('SHOWLOGIN')
					}, 500);
				} else {
					Message({
						type: 'error',
						message: res.msg
					})
				}
			})
		},
		//退出登录
		onExit() {
			localStorage.removeItem('token')
			this.isLogin = false
			Message({
				type: 'success',
				message: '已退出账号'
			})
			this.isEdit = false
			window.location.href = "/"
		},
		onHome() {
			window.location.href = "/"
		}
	}
}
</script>

<style lang="scss" scoped>
.xuan_box {
	width: 60px;
	background-color: #FFFFFF;
	height: auto;
	padding: 10px 2px;
	position: absolute;
	left: 20px;
	top: 80px;
	border-radius: 4px;
	z-index: 222;
	line-height: 30px;
	// border:1px solid red
}

.xuan_box_one {
	width: 60px;
	background-color: #FFFFFF;
	height: auto;
	padding: 10px 2px;
	position: absolute;
	left: 20px;
	top: 88px;
	border-radius: 4px;
	z-index: 222;
	line-height: 30px
}

.input-box_s {
	width: 290px;
	background: #F3F3F3;
	border-radius: 4px;
	margin-top: 22px;

	::v-deep .el-input__inner {
		background: #f3f3f3;
		height: 45px;
	}
}

.la_img {
	width: 14px;
	height: 8px;
	margin-left: 6px;
}

.flexs {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flexs1 {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.flexs2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flexs3 {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.flexs4 {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	align-self: flex-end;
}

.head {
	height: 76px;
	background: #1f2f46;
	display: flex;
	justify-content: space-between;
	color: #fff;

	.left {
		width: 30%;

		.kaui {
			margin-left: 160px;
			background: #fff;
			width: 160px;
			height: 60px;
			border-radius: 0 0 12px 12px;
			color: #000000;
			font-size: 28px;
			line-height: 60px;
			text-align: center;
			cursor: pointer;
			user-select: none;

		}
	}

	.right {
		padding: 0 80px;
		width: 70%;


		.navlist {
			// border: 1px solid #fff;
			display: flex;
			gap: 22px;
			justify-content: end;

			.item {
				font-size: 18px;
				font-weight: 400;
				color: #dbd8d8;
				line-height: 76px;
				cursor: pointer;
				user-select: none;
			}

			.active {
				font-size: 22px;
				color: fff;
				color: #FFF;

			}
		}
	}
}


.tips {
	::v-deep .el-dialog {
		border-radius: 15px;
	}

	::v-deep .el-dialog__body {
		padding: 0px 25px 0px
	}

	::v-deep .el-dialog__header {
		padding: 42px 20px 28px;

		.el-dialog__title {
			font-size: 22px;
		}

	}

	.info {
		position: relative;
		border-top: 1px solid #d1d1d1;
		padding: 20px;

		&::before {
			content: '';
			position: absolute;
			top: -3px;
			width: 100px;
			height: 3px;
			left: 50%;
			transform: translateX(-50%);
			background-color: #222;
		}

		.input-code {
			display: flex;
			align-items: center;
			gap: 20px;

			.inputc {
				width: 70%;
			}

			.code {
				cursor: pointer;
				user-select: none;
				text-align: center;
				color: #0D5E6B;
			}
		}

		.input-box {
			background: #F3F3F3;
			border-radius: 4px;
			margin-top: 22px;

			::v-deep .el-input__inner {
				background: #f3f3f3;
				height: 45px;
			}
		}
	}

	.title {
		font-size: 15px;
		font-weight: 400;
		color: #878383;
		cursor: pointer;
		user-select: none;
	}

	.bottom {
		color: black;
		display: flex;
		justify-content: space-between;
		padding: 0 30px;

		.tit {

			font-size: 16px;

			font-weight: 400;
			color: #000000;

			span {
				color: #0d5e6b;
				cursor: pointer;
				user-select: none;
			}
		}

		.tits {
			color: #878383;
			font-size: 16px;
			cursor: pointer;
			user-select: none;

		}
	}

	.btn {
		padding: 20px 30px;
	}
}</style>