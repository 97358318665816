import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'
import tailored from '../views/menu/tailored.vue'
import carRental from '../views/menu/carRental.vue'
import travel from '../views/menu/travel.vue'
import download from '../views/menu/download.vue'
import centre from '../views/personage/centre.vue'
import agreement from '../views/home/agreement.vue'
import more from '../views/vlog/more.vue'
import details from '../views/vlog/details.vue'
import airport from '../views/menu/airport.vue'
import chartered from '../views/menu/chartered.vue'
import customization from '../views/menu/customization.vue'
import qorder from '../views/order/index.vue'
import carDetails from '../views/menu/carDetails.vue'
import travelList from '../views/menu/travelList.vue'
import destinations from '../views/menu/destinations.vue'
import travelDetails from '../views/menu/travelDetails.vue'


Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home, meta: { title: '首页' } },
  { path: '/tailored', component: tailored, meta: { title: '专车服务' } },
  { path: '/carRental', component: carRental, meta: { title: '汽车租赁' } },
  { path: '/travel', component: travel, meta: { title: '旅游产品' } },
  { path: '/download', component: download, meta: { title: '下载app' } },
  { path: '/agreement', component: agreement, meta: { title: '用户协议' } },
  { path: '/airport', component: airport, meta: { title: '接送机' } },
  { path: '/chartered', component: chartered, meta: { title: '单次包车' } },
  { path: '/more', component: more, meta: { title: '更多vlog' } },
  { path: '/details', component: details, meta: { title: 'vlog详情' } },
  { path: '/customization', component: customization, meta: { title: '定制' } },
  { path: '/qorder', component: qorder, name: 'qorder', meta: { title: '确认订单' } },
  { path: '/carDetails', component: carDetails, name: 'carDetails', meta: { title: '车辆详情' } },
  { path: '/travelList', component: travelList, name: 'travelList', meta: { title: '旅游产品列表' } },
  { path: '/destinations', component: destinations, name: 'destinations', meta: { title: '热门目的地详情' } },
  { path: '/travelDetails', component: travelDetails, name: 'travelDetails', meta: { title: '旅游产品详情' } },
  {
    path: '/centre', component: centre, meta: { title: '个人中心' },
    children: [
      { path: 'order', component: () => import('@/views/personage/menu/order.vue'), meta: { title: '我的订单' } },
      { path: 'member', component: () => import('@/views/personage/menu/member.vue'), meta: { title: '会员中心' } },
      { path: 'discount', component: () => import('@/views/personage/menu/discount.vue'), meta: { title: '优惠券' } },
      { path: 'evaluate', component: () => import('@/views/personage/menu/evaluate.vue'), meta: { title: '客人评价' } },
      { path: 'promise', component: () => import('@/views/personage/menu/promise.vue'), meta: { title: '平台承诺' } },
      { path: 'about', component: () => import('@/views/personage/menu/about.vue'), meta: { title: '关于平台' } },
      { path: 'set', component: () => import('@/views/personage/menu/set.vue'), meta: { title: '设置' } },
      { path: 'message', component: () => import('@/views/personage/menu/message.vue'), meta: { title: '系统消息' } },
      { path: 'complaint', component: () => import('@/views/personage/menu/complaint.vue'), meta: { title: '投诉建议' } },
      { path: 'change', component: () => import('@/views/personage/menu/change.vue'), meta: { title: '修改邮箱/密码' } },
      { path: 'setMessage', component: () => import('@/views/personage/menu/setMessage.vue'), meta: { title: '修改个人信息' } }
    ]
  },

]

const router = new VueRouter({
  routes
})

export default router
