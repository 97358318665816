<template>
    <div class="centers">
        <div class="box">
            <el-carousel :interval="2000" arrow="always" height="670px">
                <el-carousel-item v-for="item in getImgList(info.invitationImg)" :key="item">
                    <img :src="item" alt="" height="670px" style="object-fit: cover;">
                </el-carousel-item>
            </el-carousel>
            <div class="title">{{ info.invitationTitle }}</div>
            <div class="user">
                <div class="userHeader">
                    <img :src="info.publishImg" style="object-fit: cover; width: 100%; height: 100%;">
                </div>
                <div class="userinfo">
                    <p class="userName">{{ info.publishName }}</p>
                    <p class="time">{{ disposeTime(info.createTime) }}</p>
                </div>
            </div>
            <p class="info">{{ info.invitationContext }}</p>
        </div>
    </div>
</template>

<script>
import { getDmInvitationById } from '@/api/vlog'
export default {
    name: "detailss",
    data() {
        return {
            info: {}
        }
    },
    created() {
        getDmInvitationById(this.$route.query.id).then(res => {
            console.log(res)
            this.info = res.data
        })
    },
    methods: {
        getImgList(list) {
            return list ? list.split(',') : ''
        },
        disposeTime(time) {
            let a = time ? time.slice(0, -10) : ''
            return a.replace('T', ' ')
        }
    }


}

</script>

<style lang="scss" scoped>
.box {
    min-height: 100vh;
    background: #fff;
    margin-top: 20px;
    border-radius: 12px;
    padding: 24px;

    .title {
        margin-top: 20px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 31px;
    }

    .user {
        margin-top: 10px;
        display: flex;
        gap: 16px;
        align-items: center;

        .userHeader {
            width: 44px;
            height: 44px;

            img {
                border-radius: 50%;
            }
        }

        .userinfo {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .userName {
                font-size: 15px;
                font-weight: 400;
                color: #000000;
            }

            .time {
                font-size: 12px;
                font-weight: 400;
                color: #8A8A8A;
            }
        }
    }

    .info {
        margin-top: 10px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
    }
}
</style>
