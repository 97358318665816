//登录注册相关
import { $http } from '../plugins/axios'
const { post, get } = $http


//登录
export function appLogin(params) {
    return post("/fangshan/dm-user/appLogin", { ...params })
}
//注册
export function appRegister(params) {
    return post("/fangshan/dm-user/appRegister", { ...params })
}
//注册验证码发送
export const sendAccountCode = (params) => {
    return post('fangshan/dm-user/sendAccountCode', { ...params })
}
//忘记密码
export const forgotPassword = (params) => {
    return post('fangshan/dm-user/forgotPassword', { ...params })
}
//忘记密码验证码发送
export const passwordBlackCode = (params) => {
    return post('fangshan/dm-user/passwordBlackCode', { ...params })
}
//更换手机号
export const updateUserPhone = (params) => {
    return post('fangshan/dm-user/updateUserPhone', { ...params })
}
//更换手机号验证码发送
export const updateUserPhoneToSmS = (params) => {
    return post('fangshan/dm-user/updateUserPhoneToSmS', { ...params })
}
//更换邮箱
export const updateEmail = (params) => {
    return post('fangshan/dm-user/updateEmail', { ...params })
}
//更换邮箱验证码发送
export const emailToCode = (params) => {
    return post('fangshan/dm-user/emailToCode', { ...params })
}
//文档地址（服务协议 ，隐私政策，平台介绍）
export const platFormTextType = (platFormType) => {
    return get(`fangshan/dm-user/platFormTextType?platFormType=${platFormType}`)
}
