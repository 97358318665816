<template>
  <div>
    <v-swiper pagination navigation>
      <v-swiper-slide v-for="image in images" :key="image.id">
        <img :src="image.img" width="100%" height="400px" style="object-fit: cover;" />
      </v-swiper-slide>
    </v-swiper>
    <div class="centers">
      <div class="tabbar">
        <div class="righ">
          <div class="tab" :class="item.id == act ? 'active' : ''" v-for="item in tabList" :key="item.id"
            @click="changeTab(item.id)">{{ item.typeName }}
          </div>
        </div>
        <div class="left">
          <div>
            <el-input placeholder="搜索" v-model="seach" class="input-with-select" @keyup.enter.native="onList">
              <el-button slot="prepend" icon="el-icon-search"></el-button>
            </el-input>
          </div>
        </div>
      </div>
      <div class="List">
        <destination v-for="item in list" :item="item" :key="item.id" @click.native="onInfo(item.id)"></destination>
      </div>
      <div class="paging">
        <el-pagination background layout="prev, pager, next" :total="count" :page-size="8"
          :page-count="Math.ceil(count / 3)" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
  
<script>
import { selectDmDestinationList, listDestinationType } from '@/api/home'
export default {
  data() {
    return {
      act: 1,
      seach: '',
      images: [
        {
          "id": 1,
          "img": "https://picsum.photos/seed/picsum/1200/500?1"
        },

        {
          "id": 2,
          "img": "https://picsum.photos/seed/picsum/1200/500?2"
        }
      ],
      tabList: [],
      list: [],
      count: 1, //总数据数量
      pageNum: 1
    }
  },
  created() {
    this.getList()
    listDestinationType().then(res => {
      this.tabList = res.data
      this.act = res.data[0].id
    })
  },
  methods: {
    getList() {
      selectDmDestinationList(this.pageNum, 8, 2, '', this.act).then(res => {
        this.list = res.rows
        this.count = res.total
      })
    },
    currentChange(e) {
      this.pageNum = e
      this.getList()
    },
    changeTab(id) {
      console.log(id)
      this.act = id
      this.getList()
    },
    onList() {
      this.$router.push({ path: '/travelList', query: { name: this.seach } })
    },
    onInfo(id) {
      this.$router.push({ path: '/travelDetails', query: { id } })
    }
  }

}
</script>
  
<style lang="scss" scoped>
.paging {
  padding-bottom: 30px;
  float: right;
}

.tabbar {
  margin: 35px 0;
  border-bottom: 4px solid #DADADA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;

  .righ {
    display: flex;
    gap: 54px;
    flex-wrap: wrap;

    .tab {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      cursor: pointer;
      user-select: none;
      line-height: 30px;
    }

    .active {
      color: #0D5E6B;
      position: relative;
    }

    .active::before {
      position: absolute;
      clear: both;
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background: #0D5E6B;
      top: 41px;
    }
  }
}

.List {
  display: flex;
  flex-wrap: wrap;
  gap: calc((100% - 290px * 4) / 3);
}
</style>