<template>
  <div class="inputs f-row-b-c">
    <el-input v-model="value" :placeholder="'请输入' + placeholder" @input="changeAddress" @blur="blurAddress" clearable></el-input>
    
    <div class="address-box" :style="{ 'width': width }" v-if="isSearch">
      <div :loading="isLoading">
        <div class="box-list" v-if="searchList && searchList.length > 0">
          <div class="box-item" v-for="(item, index) in searchList" :key="index" @click="currentAddress(item.mainText,item.secondaryText)">
            <div class="main ell-line1">{{ item.mainText }}</div>
            <div class="all ell-line1">—{{ item.secondaryText }}</div>
          </div>
        </div>
        <div class="no-result f-col-c-c" v-else>
          未查找到相似信息
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addressSearch,
  addressSearchEnglish
} from '@/api/home'
export default {
  name: "address-frame",
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    }

  },
  data() {
    return {
      value: '',
      searchList: [],
      isSearch: false,
      isLoading: false,
      language: 'zh',
    };
  },
  created() {
    if (localStorage.getItem('language')) {
      this.language = localStorage.getItem('language')
    }
  },
  methods: {
    //地址查找
    changeAddress(val) {
      if (!!val) {
        this.isSearch = true;
        this.getAddressList(val);
      } else {
        this.isSearch = false;
        this.searchList = [];
      }
    },
    //获取相似列表
    getAddressList(val) {
      if (this.language == 'zh') {
        addressSearch(val).then(res => {
          if (res.code === 200) {
            this.isSearch = true;
            this.isLoading = true;
            this.searchList = res.data;
          }
        }).finally(() => {
          this.isLoading = false;
        })
      } else if (this.language == 'en') {
        addressSearchEnglish(val).then(res => {
          if (res.code === 200) {
            this.isSearch = true;
            this.isLoading = true;
            this.searchList = res.data;
          }
        }).finally(() => {
          this.isLoading = false;
        })
      }
    },
    //选择当前地址
    currentAddress(val,all) {
      this.value = all + val;
      this.$emit('inputValue', this.value);
      this.isSearch = false;
    },
    blurAddress() {
      const obj = this.searchList.find((item) => { this.value === item.secondaryText + item.mainText })
      if (!obj) {
        this.value = ''
      }
      setTimeout(() => {
        this.isSearch = false;
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
.ell-line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inputs {
  position: relative;
  padding: 0 12px;
  background: #FFFFFF;
  border-radius: 6px;

  input {
    width: 100%;
  }
}

.address-box {
  position: absolute;
  top:46px;
  left: 0;
  z-index: 1;
  padding: 0 10px;
  width: 266px;
  min-height: 100px;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 0 5px #ddd;

  .address-load {
    height: 100px;
  }

  .box-list {
    .box-item {
      line-height: 35px;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        background: #f5f5f5;
      }
    }
    .all {
      padding-left: 10px;
      font-size: 15px;
      color: #666;
    }
  }

  .no-result {
    line-height: 100px;
    font-size: 15px;
    color: #666;
  }
}
</style>