import axios from 'axios'
import { Message } from 'element-ui'
import httpUrl from './url'

const defaultConfig = {
  baseURL: httpUrl.baseUrl, // 域名
  timeout: 10000,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    'content-type': 'application/json'
  }
}
const http = axios.create(defaultConfig)
http.all = axios.all
http.spread = axios.spread

http.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = localStorage.getItem('token')
  }
  return config
}, error => {
  // console.error(error)
  Promise.reject(error)
})


http.interceptors.response.use(res => {
  if (res.data.code === 0) {
    Message({
      type: 'error',
      message: res.data.msg
    })
  }
  if (res.data.code === 401) {
    Message({
      type: 'error',
      message: '您还未登录，已为您跳转到登录首页'
    })

    // setTimeout(() => {
    //   window.location.href = "/"
    //   // window.vm.$store.commit('SHOWLOGIN')
    // }, 1000)
  }
  return res.data



}, error => {
  const { response: { status } } = error
  return {
    code: status
  }
})

const ajax = {
  get(url, params, config) {
    return http.get(url, {
      params,
      ...config
    })
  },
  post(url, data, config) {
    return http.post(url, data, config)
  },
  put: http.put,
  deleted: http.delete
}

export const $http = ajax
export default {
  install(Vue) {
    // Vue.prototype.$api = api
    Vue.$http = ajax
    Vue.prototype.$http = ajax
  },
  $http: ajax
}
