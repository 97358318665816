import Vue from "vue";


import VHeader from "@/components/VHeader.vue";
import VGap from "@/components/VGap.vue";
import VTitle from "@/components/VTitle.vue";
import VSidebar from "@/components/pageModule/VSidebar.vue";
import VvlogItem from "@/components/vlog/VvlogItem";
import destination from "@/components/destination/destination.vue";
import VOrder from "@/components/VOrder.vue";
import VUpload from "@/components/VUpload.vue";


import VSwiper from '@/components/swiper/VSwiper'
import VSwiperSlide from '@/components/swiper/VSwiperSlide'





Vue.component('VHeader', VHeader);
Vue.component('VGap', VGap);
Vue.component('VTitle', VTitle);
Vue.component('VSidebar', VSidebar);
Vue.component('VvlogItem', VvlogItem);
Vue.component('destination', destination);
Vue.component('VOrder', VOrder);
Vue.component('VUpload', VUpload);



Vue.component('VSwiper', VSwiper);
Vue.component('VSwiperSlide', VSwiperSlide);