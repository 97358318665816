import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/element'

import './plugins/PaC'
import './plugins/swiper'
import './assets/css/reset.css'
import './plugins/axios'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.config.productionTip = false

//回顶部
Vue.prototype.$scrollTo = (x = 0, y = 0, type = 'instant') => {
  window.scrollTo({
    top: x,
    left: y,
    behavior: type // 滚动行为：smooth平滑滚动，instant瞬间滚动，默认值auto，等同于instant
  })
}
window.vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
