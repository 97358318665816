<template>
    <div class="box">
        <div class="top">
            <div class="orderNum">{{ item.orderNo }}</div>
            <div class="time">{{ item.createTime }}</div>
        </div>
		<div class="flexs1">
			<div class="type" v-if="item.orderTypeCode == 'SINGLE_CHARTER'">单次包车</div>
			<div class="type" v-if="item.orderTypeCode == 'PICKUP_SERVICES'">接送机服务</div>
			<div class="type" v-if="item.orderTypeCode == 'CAR_RENTAL'">汽车租赁</div>
			<div class="type" v-if="item.orderTypeCode == 'TOURISM_PRODUCTS'">旅游产品</div>
			<div class="type" v-if="item.orderTypeCode == 'SPECIAL_CAR_SERVICE'">专车服务</div>
			<div class="type" v-if="item.orderTypeCode == 'POPULAR_DESTINATION_SERVICES'">热门目的地服务</div>
			<div class="type" v-if="item.orderTypeCode == 'PERSONAL_TAILOR'">私人定制</div>
			<div class="bang_btn flexs" v-if="item.orderTypeCode == 'TOURISM_PRODUCTS'">帮人预定</div>
		</div>
        

        <!-- 热门目的地 -->
        <div v-if="item.orderTypeCode == 'POPULAR_DESTINATION_SERVICES'">
            <div class="info">
                {{ item.desName }}
            </div>
            <div class="price">￥{{ item.sfMoney }}</div>
            <div class="jia">
                <div class="title">订单金额：<span>￥{{ item.desMoney }}</span></div>
                <div class="title">会员折扣：<span>-￥{{ item.vipDiscount }}</span></div>
                <div class="title">优惠券折扣：<span>-￥{{ item.couponMoney }}</span></div>
            </div>
        </div>
        <!-- 专车服务 -->
        <div v-if="item.orderTypeCode == 'SPECIAL_CAR_SERVICE'">
            <div class="info">
                {{ item.setOutAddress }}——{{ item.arriveOutAddress }}
            </div>
            <div class="price">￥{{ item.sfMoney }}</div>
            <div class="jia">
                <div class="title">订单金额：<span>￥{{ item.sfMoney }}</span></div>
                <div class="title">会员折扣：<span>-￥{{ item.vipDiscount }}</span></div>
                <div class="title">优惠券折扣：<span>-￥{{ item.couponMoney }}</span></div>
            </div>
            <div class="serve">
                <div class="title">预约时间：<span>{{ item.appointmentTime }}</span></div>
                <div class="title">车型：<span> {{ item.carTypeName }}</span></div>
                <div class="title" v-if="item.theRaising == 2 || item.theBaby">预约服务：<span
                        v-if="item.theRaising == 2">举牌接机</span>，<span v-if="item.theBaby">宝宝座椅 ×{{ item.theBaby }}</span>
                </div>
            </div>
        </div>
        <!-- 旅游产品 -->
        <div v-if="item.orderTypeCode == 'TOURISM_PRODUCTS'">
            <div class="info">
                {{ item.desName }} | 服务日期：{{ item.appointmentTime.slice(0, -5) }}
            </div>
            <div class="price">￥{{ item.sfMoney }}</div>
            <div class="jia">
                <div class="title">订单金额：<span>￥{{ item.desMoney }}</span></div>
                <div class="title">会员折扣：<span>-￥{{ item.vipDiscount }}</span></div>
                <div class="title">优惠券折扣：<span>-￥{{ item.couponMoney }}</span></div>
            </div>
        </div>
        <!-- 接送机服务 -->
        <div v-if="item.orderTypeCode == 'PICKUP_SERVICES'">
            <div class="info">
                {{ item.setOutAddress }}——{{ item.arriveOutAddress }} | 航班号：{{ item.airplaneNo }}
            </div>
            <div class="price">￥{{ item.sfMoney }}</div>
            <div class="jia">
                <div class="title">订单金额：<span>￥{{ item.sfMoney }}</span></div>
                <div class="title">会员折扣：<span>-￥{{ item.vipDiscount }}</span></div>
                <div class="title">优惠券折扣：<span>-￥{{ item.couponMoney }}</span></div>
            </div>
            <div class="serve">
                <div class="title">预约时间：<span>{{ item.appointmentTime }}</span></div>
                <div class="title">车型：<span> {{ item.carTypeName }}</span></div>
                <div class="title" v-if="item.theRaising == 2 || item.theBaby">预约服务：<span
                        v-if="item.theRaising == 2">举牌接机</span>，<span v-if="item.theBaby">宝宝座椅 ×{{ item.theBaby }}</span>
                </div>
            </div>
        </div>
        <!-- 单次包车 -->
        <div v-if="item.orderTypeCode == 'SINGLE_CHARTER'">
            <div class="info">
                出发地：{{ item.setOutAddress }}
            </div>
            <div class="price">￥{{ item.sfMoney }}</div>
            <div class="jia">
                <div class="title">订单金额：<span>￥{{ item.sfMoney }}</span></div>
                <div class="title">会员折扣：<span>-￥{{ item.vipDiscount }}</span></div>
                <div class="title">优惠券折扣：<span>-￥{{ item.couponMoney }}</span></div>
            </div>
            <div class="serve">
                <div class="title">预约时间：<span>{{ item.appointmentTime }}</span></div>
                <div class="title">车型：<span> {{ item.carTypeName }}</span></div>
                <div class="title" v-if="item.theRaising == 2 || item.theBaby">预约服务：<span
                        v-if="item.theRaising == 2">举牌接机</span>，<span v-if="item.theBaby">宝宝座椅 ×{{ item.theBaby }}</span>
                </div>
            </div>
        </div>
        <!-- 汽车租赁 -->
        <div v-if="item.orderTypeCode == 'CAR_RENTAL'">
            <div class="info">
                车型：{{ item.desName }}&nbsp;&nbsp;&nbsp;&nbsp;天数：{{ item.rentNum }}天
            </div>
            <div class="price">￥{{ item.sfMoney }}</div>
            <div class="jia">
                <div class="title">订单金额：<span>￥{{ item.sfMoney }}</span></div>
                <div class="title">会员折扣：<span>-￥{{ item.vipDiscount }}</span></div>
                <div class="title">优惠券折扣：<span>-￥{{ item.couponMoney }}</span></div>
            </div>
        </div>
        <!-- 私人定制 -->
        <div v-if="item.orderTypeCode == 'PERSONAL_TAILOR'">
            <div class="info">
                预约时间：{{ item.appointmentTime }}&nbsp;&nbsp;&nbsp;&nbsp;
                目的地城市：{{ item.arriveOutAddress }}
            </div>
            <div class="price">￥{{ item.sfMoney }}</div>
            <div class="jia">
                <div class="title">订单金额：<span>￥{{ item.sfMoney }}</span></div>
                <!-- <div class="title">会员折扣：<span>-￥{{ item.vipDiscount }}</span></div>
                <div class="title">优惠券折扣：<span>-￥{{ item.couponMoney }}</span></div> -->
            </div>
        </div>
        <div class="bottom">
            <div class="person">
                <img src="../../public/img/icon_user.png" alt="">
                <div class="name">{{ item.name }}</div>
                <div class="phone">{{ item.phone }}</div>
            </div>
            <div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VOrder',
    props: {
        item: {
            type: Object,
            default: 0
        }
    },
    created() {
        console.log(this.item)
    }
}
</script>

<style lang="scss" scoped>
	.flexs {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.bang_btn{
		width: 80px;
		height: 24px;
		background: #32B16C;
		border-radius: 8px;
		color: #FFFFFF;
		font-size: 15px;
		margin-left: 10px;
	}
	.flexs1 {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	
	.flexs2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.flexs3 {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	
	.flexs4 {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		align-self: flex-end;
	}
.box {
    padding: 14px;
}

.top {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
}

.type {
    font-size: 22px;
    font-weight: bold;
    color: #0D5E6B;
    line-height: 39px;
}

.info {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 23px;
}

.price {
    font-size: 16px;
    font-weight: 400;
    color: #FF0909;
    line-height: 37px;
}

.jia,
.serve {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    display: flex;
    gap: 40px;

    span {
        color: #000000;
    }
}

.bottom {
    display: flex;
    align-items: center;
    margin-top: 8px;
    justify-content: space-between;

    .person {
        display: flex;
        gap: 10px;
        align-items: center;

        img {
            width: 16px;
            height: 16px;
        }

        font-size: 16px;
        font-weight: 400;
        color: #000000;
    }
}
</style>