//我的相关
import { $http } from '../plugins/axios'
const { post, get } = $http


//个人信息详情功能
export const getHighImportInfo = () => {
    return get('fangshan/dm-user/getDmUserDetailInfo')
}
//个人信息的修改提交接口
export const userInfoUp = (params) => {
    return post('fangshan/dm-user/userInfoUp', { ...params })
}
//投诉建议
export const userComplaintUp = (params) => {
    return post('fangshan/dm-user/userComplaintUp', { ...params })
}
//平台承诺列表查询
export const listIDmPlatformCommitments = () => {
    return get('fangshan/dm-user/listIDmPlatformCommitments')
}
//用户优惠券列表
export function userCouponList(params) {
    return get("/fangshan/dm-user/userCouponList", { ...params })
}
//个人会员信息
export const getDmUserDetailInfo = () => {
    return get('/fangshan/dm-user/getDmUserDetailInfo')
}
//VIP权益模板内容
export const selectVipContextList = () => {
    return get('/fangshan/dm-user/selectVipContextList')
}
//热门目的地列表
export const selectDmDestinationList = (pageNum, pageSize) => {
    return get(`/fangshan/dm-user/selectDmDestinationList?pageNum=${pageNum}&pageSize=${pageSize}`)
}
//客人评价
export const listUserAppraise = (pageNum, pageSize) => {
    return get(`/fangshan/dm-user/listUserAppraise?pageNum=${pageNum}&pageSize=${pageSize}`)
}
