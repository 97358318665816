<template>
    <div class="item">
        <div class="img-box">
            <img :src="getImgList(item.invitationImg)[0]" style="object-fit: cover; width: 100%; height: 100%;">
        </div>
        <div class="info">
            <p class="title">{{ item.invitationTitle }}</p>
            <p class="infot">{{ item.invitationContext }}</p>
            <div class="user">
                <div class="userHeader">
                    <img :src="item.publishImg" style="object-fit: cover; width: 100%; height: 100%;">
                </div>
                <div class="userinfo">
                    <p class="userName">{{ item.publishName }}</p>
                    <p class="time">{{ disposeTime(item.createTime) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VvlogItem",
    props: {
        item: {
            type: Object,
            default: 0
        },
    },
    created() {
        // console.log(this.item)
    },
    methods: {
        getImgList(list) {
            return list ? list.split(',') : ''
        },
        disposeTime(time) {
            let a = time ? time.slice(0, -10) : ''
            return a.replace('T', ' ')
        },
    }
}
</script>

<style lang="scss" scoped>
.item {
    margin: 0 0 30px 0;
    width: 388px;
    height: 510px;
    background: #FFFFFF;
    border-radius: 12px;
    cursor: pointer;
    user-select: none;

    .img-box {
        height: 300px;

        img {
            border-radius: 12px 12px 0 0;
        }
    }

    .info {
        padding: 20px 14px;

        .title {
            font-size: 25px;
            font-weight: 400;
            color: #000000;
            margin-bottom: 14px;
        }

        .infot {
            font-size: 16px;
            font-weight: 400;
            color: #8A8A8A;
            line-height: 25px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .user {
            margin-top: 16px;
            display: flex;
            gap: 16px;
            align-items: center;

            .userHeader {
                width: 44px;
                height: 44px;

                img {
                    border-radius: 50%;
                }
            }

            .userinfo {
                display: flex;
                flex-direction: column;
                gap: 6px;

                .userName {
                    font-size: 15px;
                    font-weight: 400;
                    color: #000000;
                }

                .time {
                    font-size: 12px;
                    font-weight: 400;
                    color: #8A8A8A;
                }
            }
        }
    }
}
</style>