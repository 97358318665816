<template>
    <div>
        <div class="header">
            <v-swiper pagination navigation>
                <v-swiper-slide v-for="image in lunboList" :key="image.id">
                    <!-- showType记得改成1 -->
                    <img :src="image.bannerImg" width="100%" height="700px" style="object-fit: cover;"
                        v-if="image.showType == 2" @click="onHref(image.theSkip, image.skipInfo)" />
                </v-swiper-slide>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </v-swiper>
            <div class="search">
                <div class="nav">
                    <div class="item" :class="activeNav == item.id ? 'active' : ''" v-for="item in navList" :key="item.id"
                        @click="changeNav(item.id)">{{ item.title }}
                    </div>
                </div>
                <div class="bar">
                    <div class="input-box">
                        <el-input v-model="input" placeholder="请输入关键字"></el-input>
                        <button class="button" @click="onSerch">搜索</button>
                    </div>
                </div>
            </div>
        </div>
        <v-gap></v-gap>
        <!-- <div class="swiper1">
            <v-swiper pagination navigation>
                <v-swiper-slide v-for="image in images" :key="image.id">
                    <img :src="image.img" width="100%" height="100px" style="object-fit: cover;" />
                </v-swiper-slide>
            </v-swiper>
        </div> -->
        <div class="centers">
            <div class="title">
                <v-title>远山vlog</v-title>
                <div class="right" @click="onMero">
                    <img src="../../../public/img/icon_more.png" width="100%" height="100%">
                </div>
            </div>
            <div class="vlogList">
                <vvlog-item :item="item" v-for="(item, index) in vlogList" :key="index"
                    @click.native="onDetails(item.id)"></vvlog-item>
            </div>
        </div>
        <div class="swiper1" @click="onCustomization">
            <img src="https://picsum.photos/seed/picsum/1200/500?1" alt="" width="100%" height="200px"
                style="object-fit: cover;" />
        </div>
        <div class="centers">
            <v-title>热门目的地</v-title>
            <div class="bournList">
                <destination :item="item" v-for="(item, index) in bournList" :key="index" @click.native="onInfo(item.id)">
                </destination>
            </div>
            <div class="paging">
                <el-pagination background layout="prev, pager, next" :total="count" :page-size="8"
                    :page-count="Math.ceil(count / 3)" @current-change="currentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { selectBannerList, selectDmDestinationList } from '@/api/home'
import { listDmInvitations } from '@/api/vlog'
export default {
    data() {
        return {
            images: [
                {
                    "id": 1,
                    "img": "https://picsum.photos/seed/picsum/1200/500?1"
                },

                {
                    "id": 2,
                    "img": "https://picsum.photos/seed/picsum/1200/500?2"
                }
            ],
            input: '',//搜索内容
            navList: [
                { id: 1, title: '汽车租赁' },
                { id: 2, title: '旅游产品' }
            ],
            activeNav: 1,
            lunboList: [], //轮播列表  
            vlogList: [], //vlog列表
            bournList: [], //热门目的地列表

            //热门目的地分页
            count: 1, //总数据数量
            pageNum: 1

        }
    },
    created() {
        selectBannerList().then(res => {
            this.lunboList = res.data
        })
        listDmInvitations(1, 3).then(res => {
            this.vlogList = res.rows
        })
        this.getList()

    },
    methods: {
        onSerch() {
            if (this.activeNav == 1) {
                console.log(1)
                this.$router.push({ path: '/carRental', query: { name: this.input } })
            } else {
                console.log(2)
                this.$router.push({ path: '/travelList', query: { name: this.input } })
            }
        },
        currentChange(e) {
            this.pageNum = e
            this.getList()
        },
        getList() {
            selectDmDestinationList(this.pageNum, 8, 1, '', '').then(res => {
                this.bournList = res.rows
                this.count = res.total
            })
        },
        onHref(isHref, address) {
            if (isHref == 2) {
                location.href = 'http://www.baidu.com'
                // location.href = address
            }
        },
        changeNav(id) {
            this.activeNav = id
        },
        //更多vlog
        onMero() {
            this.$router.push('/more')
        },
        //定制
        onCustomization() {
            this.$router.push('/customization')
        },
        //目的地详情
        onInfo(id) {
            this.$router.push({ path: '/destinations', query: { id } })
        },
        //vlog详情
        onDetails(id) {
            this.$router.push({ path: '/details', query: { id } })
        }
    }
}
</script>

<style scoped lang="scss">
.header {
    position: relative;
}

.search {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    .nav {
        width: 1200px;
        // border: 1px solid #000;
        margin: 0 auto;
        display: flex;

        .item {
            width: 140px;
            height: 47px;
            color: #fff;
            font-size: 20px;
            text-align: center;
            line-height: 47px;
            cursor: pointer;
            user-select: none;
        }

        .active {
            background: rgba($color: #11121b, $alpha: .6);

        }
    }

    .bar {
        height: 60px;
        background: rgba($color: #000000, $alpha: .6);

        .input-box {
            height: 60px;
            margin: 0 auto;
            display: flex;
            gap: 24px;
            align-items: center;
            width: 800px;

            .button {
                width: 180px;
                height: 44px;
                border-radius: 2px;
                background: #0D5E6B;
                color: #fff;
                font-size: 20px;
                cursor: pointer
            }
        }

    }
}

.centers {
    margin: 0 auto;
    width: 1300px;
    // padding: 20px 0;

    .title {
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        font-weight: bold;
        color: #020202;
        line-height: 14rpx;
        align-items: center;

        .right {
            width: 44px;
            height: 44px;
            cursor: pointer;
            user-select: none;
        }


    }

    .vlogList {
        display: flex;
        flex-wrap: wrap;
        gap: calc((100% - 388px * 3) / 2);
        // gap: calc((100% - 388px * 3)/2) ;
    }

    .bournList {
        display: flex;
        flex-wrap: wrap;
        gap: calc((100% - 290px * 4) / 3);
    }

}

.paging {
    padding-bottom: 30px;
    float: right;
}
</style>