<template>
  <div>
    <div class="header">
      <v-swiper pagination navigation>
        <v-swiper-slide v-for="image in images" :key="image.id">
          <img :src="image.img" width="100%" height="700px" style="object-fit: cover;" />
        </v-swiper-slide>
      </v-swiper>
      <div class="cover">
        <div class="title">中文包车畅游全世界</div>
        <div class="tit1">10万+当地华人司导服务，按天包车、接送机、单次用车</div>
        <div class="btn">
          <img src="../../../public/img/img_pgxz.png" alt="">
          <img src="../../../public/img/img_azxz.png" alt="">
        </div>
      </div>
    </div>
    <div class="centers">
      <div class="box">
        <div class="right">
          <div class="on1"></div>
          <div class="on2">
            <div class="t">远山旅游APP</div>
            <img src="../../../public/img/word_gg.png" alt="">
          </div>
        </div>
        <div class="left">
          <img src="../../../public/img/img_ewm.png" alt="">
          <div class="tt">扫码下载远山旅游APP</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      images: [
        {
          "id": 1,
          "img": "https://picsum.photos/seed/picsum/1200/500?1"
        },

        {
          "id": 2,
          "img": "https://picsum.photos/seed/picsum/1200/500?2"
        }
      ],
    }
  }
}
</script>
  
<style lang="scss" scoped>
.header {
  position: relative;

  .cover {
    width: 100%;
    height: 700px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    .title {
      font-size: 129px;
      text-align: center;
      font-family: HYChengXingJ;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 266px;
      text-shadow: 2px 6px 4px rgba(0, 0, 0, 0.33);
    }

    .tit1 {
      font-size: 29px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 38px;
      text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.33);
      text-align: center;
    }

    .btn {
      display: flex;
      margin: 0 auto;
      margin-top: 160px;
      width: 60%;
      justify-content: space-between;

      img {
        width: 320px;
        height: 100px;
      }
    }
  }
}

.box {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  height: 200px;
  background: #FFFFFF;

  .right {
    display: flex;
    align-items: center;
    gap: 30px;

    .on1 {
      width: 160px;
      height: 160px;
      background: #282828;
      border-radius: 50%;
    }

    .on2 {
      display: flex;
      flex-direction: column;
      gap: 22px;

      .t {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
      }
    }
  }

  .left {
    width: 220px;
    text-align: center;

    img {
      margin: 0 auto;
      width: 124px;
      height: 124px;
    }

    .tt {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 39px;
    }
  }
}
</style>