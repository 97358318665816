<template>
    <div class="title">
        <div class="img"> <img src="../../public/img/icon_xz.png" width="100%" height="100%" /></div>
        <p>
            <slot></slot>
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #020202;
    line-height: 14rpx;
    width: 160px;
    padding: 30px 0;

    .img {
        width: 18px;
        height: 18px;

    }
}
</style>