<template>
    <div class="centers">
        <div class="box">
            <div class="top">
                <div class="right">
                    <img :src="info.desImg" alt="" style="object-fit: cover;">
                </div>
                <div class="left">
                    <div>
                        <div class="name">{{ info.desName }}</div>
                        <div class="price">￥{{ info.desMoney }}</div>
                    </div>
                    <div class="btns">
                        <button class="submit-btn" @click="goBuy">立即购买</button>
                    </div>
                </div>
            </div>
            <div class="gap">---------- 目的地详情 ----------</div>
            <el-carousel :interval="2000" arrow="always" height="670px">
                <el-carousel-item v-for="item in getImgList(info.desContext)" :key="item">
                    <img :src="item" alt="" height="670px" style="object-fit: cover;">
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
import { selectDmDestinationInfo } from '@/api/home'
export default {
    data() {
        return {
            info: {}
        }
    },
    created() {
        selectDmDestinationInfo(this.$route.query.id).then(res => {
            this.info = res.data
            // console.log(res)
        })
    },
    methods: {
        getImgList(list) {
            return list ? list.split(',') : ''
        },
        goBuy() {
            let order = {
                orderSource: 'PC',
                orderTypeCode: 'POPULAR_DESTINATION_SERVICES',
                allMoney: this.info.desMoney,
                info: this.info,
                refDestinationId: this.info.id
            }
            this.$router.push({
                name: 'qorder',
                query: {
                    obj: encodeURIComponent(JSON.stringify(order)),
                    type: 1,
                }
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.box {
    background: #FFF;
    // height: 100vh;
    margin-top: 10px;
    padding: 14px;
    border-radius: 12px;

    .top {
        display: flex;
        gap: 20px;
        height: 260px;

        .right {
            width: 830px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .left {

            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .name {
                font-size: 26px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 36px;
            }

            .price {

                font-size: 26px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FF0909;
                line-height: 41px;
            }

            .btns {
                width: 300px;
            }

        }
    }

    .gap {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        line-height: 37px;
        margin: 60px 0 20px 0;
        text-align: center;
    }
}
</style>