<template>
    <div class="centers">
        <div class="box">
            <div v-if="type == 1">
                <div class="title">用户协议</div>
                <div class="info" v-html="xieyi"></div>
            </div>
           <div v-if="type ==2">
            <div class="title">隐私政策</div>
            <div class="info" v-html="zhengce"></div>
           </div>
           
        </div>
    </div>
</template>

<script>
import { platFormTextType } from '@/api/loginAbout'
export default {
    name: 'agreement',
    data() {
        return {
            xieyi: '',
            zhengce: '',
            type:''
        }
    },
    created() {

        this.type = this.$route.query.type
        platFormTextType('USERAGREEMENT').then(res => {
            this.xieyi = res.data.context
        })
        platFormTextType('PRIVACYPOLICY').then(res => {
            this.zhengce = res.data.context
        })
    },
    
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.box {
    margin-top: 20px;
    background: #FFF;
    height: 100vh;
    padding: 30px;

    .title {
        font-size: 26px;
        font-weight: bold;
        color: #000000;
        line-height: 44px;
        text-align: center;
    }

    .info {
        font-size: 18px;
        color: #000000;
        line-height: 30px;
    }
}
</style>