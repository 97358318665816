<template>
  <div id="app">
    <v-header></v-header>
    <router-view></router-view>
  </div>
</template>
<script>
// 在你的入口文件或Vue组件中
// 确保替换YOUR_API_KEY为你的谷歌地图API密钥
 
// 引入谷歌地图API
  const googleMapScript = document.createElement('script');
  googleMapScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=AIzaSyBLbx8VAJQeGrqMxyu1AjN2wM5GD6VFfcc&libraries=places`);
  googleMapScript.addEventListener('load', () => {
    // 初始化Vue实例
  });
  document.head.appendChild(googleMapScript);
</script>
<style lang="scss">
.centers {
  width: 1200px;
  margin: 0 auto;
}

button.submit-btn {
  height: 54px;
  width: 100%;
  line-height: 54px;
  background: #0D5D69;
  border-radius: 12px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
  user-select: none;

  &:active {
    background-color: darken(#0D5D69, 5%);
  }
}

button.submit-btn[disabled] {
  opacity: 0.3;
  background: #0D5D69;
  color: #FFFFFF;
  cursor: not-allowed;
}
</style>
