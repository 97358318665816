<template>
    <div class="centers">
        <div class="box">
            <div class="title">出发地</div>
            <div class="flexs1">
                <div class="inputs">
                    <el-select v-model="info.country_id" placeholder="请选择国家" @change="getCard($event, 'country')">
                        <el-option v-for="item in countrys" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="inputs">
                    <el-select v-model="info.provinces_id" placeholder="请选择省份" @change="getCard($event, 'province')">
                        <el-option v-for="item in provinces" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="inputs">
                    <el-select v-model="info.city_id" placeholder="请选择城市" @change="getCard($event, 'city')">
                        <el-option v-for="item in citys" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <!-- <div class="input-box" style="width: 20%;margin-bottom: 10px;">
                    <el-input v-model="info.start" placeholder="请输入出发地详细地址"></el-input>
                </div> -->
                <address-frame @inputValue="inputValueStart" placeholder="出发地详细地址"></address-frame>
            </div>

            <div class="title">出发时间</div>
            <div class="inputs">
                <el-date-picker v-model="info.Stime" type="datetime" placeholder="选择日期时间" default-time="12:00:00"
                    @change="getTime" value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </div>
            <div class="title">车型</div>
            <div class="flexs1">
                <div class="inputs">
                    <el-select v-model="info.value" placeholder="请选择" @change="getCard($event, 'car')">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="flexs1">
                    <div class="flexs1" style="cursor: pointer" @click="chooseCar()">
                        <div>车型介绍</div>
                        <img src="../../../public/img/icon_shuaxin.png" class="jie_img" />
                    </div>
                    <div class="flexs1" style="margin-left: 16px;cursor: pointer" @click="chooseCar1()">
                        <div>行李示例图</div>
                        <img src="../../../public/img/icon_shuaxin.png" class="jie_img" />
                    </div>
                </div>
            </div>

            <div class="tipes" v-if="isShowcar">该车系共{{ changeCarinfo.passengerCapacity }}个座位，最多可携带{{
                changeCarinfo.luggageBearCapacity }}kg行李</div>
            <div class="title">用车时长</div>
            <div class="inputs">
                <el-select v-model="info.carTime" placeholder="请选择">
                    <el-option v-for="item in carTimeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="title">平台服务</div>
            <div style="display: flex; align-items: center; margin-top: 8px; gap: 18px;">
                <el-checkbox label="举牌接机" v-model="Raising"></el-checkbox>
                <el-checkbox v-model="theBaby">
                    <div class="child">
                        <div>宝宝座椅</div>
                    </div>
                </el-checkbox>
                <el-input-number v-model="num" v-if="theBaby == true" :min="1" size="small"></el-input-number>
                <el-checkbox label="接机服务" v-model="songServe"></el-checkbox>
            </div>
        </div>
       
        <!-- 行李示例图弹框 -->
        <el-dialog :visible.sync="carTan1" width="30%" top="35vh">
            <div class="title flexs" style="font-size: 22px;">行李示例图:</div>
            <div class="flexs" style="width: 100%;">
                <div v-if="info.value != ''">
					<div style="width: 100%;height: 300px;" v-for="(item, index) in changeCarinfo.picPath" :key="index" >
						<img :src="item" class="car_img"/>
					</div>
				</div>
                <div v-else style="margin-top: 20px;">请选择车型</div>
            </div>
        </el-dialog>
        <!-- 车型介绍弹框 -->
        <el-dialog :visible.sync="carTan" width="30%" top="35vh">
            <div class="title flexs" style="font-size: 22px;">车型介绍:</div>
            <div class="" style="width: 100%;">
				<div v-if="info.value != ''">
					<div style="margin: 20px 0;min-height: 300px;" v-html="changeCarinfo.introduce || '无介绍'"></div>
				</div>
				<div v-else style="margin-top: 20px;text-align: center;">请选择车型</div>
			</div>
        </el-dialog>
        <!-- 价格弹框 -->
        <el-dialog :visible.sync="dialogVisible" width="30%" top="35vh">
            <div class="title " style="font-size: 22px;">服务价格:</div>
            <div class="tcBOX">
                <div class="price">￥{{ price }}</div>
                <div class="title flexs1" style="font-size: 22px;">价格详情:</div>
                <div class="flexs3" style="width: 100%;flex-wrap: wrap;margin: 15px 0;line-height: 31px;">
                    <div class="flexs1" style="margin-right: 24px">
                        <div class="jia_text">价格：</div>
                        <div>￥{{ price }}</div>
                    </div>
                    <div class="flexs1" style="margin-right: 24px">
                        <div class="jia_text">距离：</div>
                        <div>{{ juli }}km</div>
                    </div>
                    <div class="flexs1" style="margin-right: 24px">
                        <div class="jia_text">用时：</div>
                        <div>{{ info.carTime }}H</div>
                    </div>
                    <div class="flexs1" style="margin-right: 24px">
                        <div class="jia_text">车型：</div>
                        <div>{{ changeCarinfo.carTypeName }}</div>
                    </div>
                    <div class="flexs1" style="margin-right: 24px">
                        <div class="jia_text">过路桥费：</div>
                        <div>￥{{ cost }}</div>
                    </div>
                </div>
                <div class="btns">
                    <button class="submit-btn" @click="onOrder">立即出发</button>
                </div>

            </div>
        </el-dialog>
        <div class="box">
            <div class="btns">
                <button class="submit-btn" @click="onCalculate" :disabled="del">计算价格</button>
            </div>
        </div>
    </div>
</template>

<script>
import { Message } from 'element-ui'
import { listAllCarConfig, calculateSingleCharterPrice, listTime, getCpc } from '@/api/order'
import addressFrame from '@/components/address-frame/address-frame.vue'
export default {
    name: 'chartered',
    components:{
		addressFrame
	},
    data() {
        return {

            info: {
                start: '',
                carTime: '',
                value: '', //选中的车形id
                Stime: '', //出发时间,
                country_id: '',
                provinces_id: '',
                city_id: ''
            },
            checkList: [],
            options: [],
            carTimeList: [],

            Raising: false,  //举牌接机自定义状态 
            theBaby: false,   //宝宝座椅自定义状态
            num: 1,//宝宝座椅数量自定义
            songServe: false,//接机服务自定义状态

            theRaising: 1, //是否需要接机服务
            theBabyNum: 0, //宝宝座椅数量
            songRaising: 1, //是否需要接机服务

            carTan: false,
            carTan1: false,
            dialogVisible: false,
            isShowcar: false,
            changeCarinfo: {}, //选中的车型详情,
            countrys: [],
            provinces: [],
            citys: [],

            //计算价格参数
            price: "", //订单价格
            juli: "",
            hour: "",
            cost: "",
        }
    },
    computed: {
        del() {
            for (let key in this.info) {
                if (this.info[key] === '') {
                    return true;
                }
            }
            return false;
        }
    },
    created() {
        getCpc({
            pid: 0
        }).then(res => {
            this.countrys = res.data
        })
        listAllCarConfig().then(res => {
            res.data.forEach(i => {
                i.label = i.carTypeName
                i.value = i.id
                i.introduce = i.introduce
                i.picPath = [i.picPath]
            })
            this.options = res.data
            console.log(this.options, 'this.options')
        })
        listTime().then(res => {
            res.data.forEach(i => {
                i.label = i.rentalDuration + 'H'
                i.value = i.rentalDuration
            })
            this.carTimeList = res.data
        })
    },
    methods: {
        //赋值出发地地址
		inputValueStart(e) {
			this.info.start = e;
		},
        chooseCar() {
            this.carTan = !this.carTan
        },
        chooseCar1() {
            this.carTan1 = !this.carTan1
        },
        //获取出发时间
        getTime(e) { },
        //获取车型id
        getCard(e, type) {
            console.log(e, type)
            if (type == 'car') {
                //选择车型
                this.isShowcar = true
                this.changeCarinfo = this.options.filter(i => i.id == e)[0]

            } else if (type == 'country') {
                //选择国家
                const countrys = Array.isArray(this.countrys) ? this.countrys : Object.values(this.countrys);
                this.countryinfo = countrys.filter(i => i.id == e)[0]
                getCpc({
                    pid: e
                }).then(res => {
                    this.provinces = res.data
                })
            } else if (type == 'province') {
                //选择省份
                const provinces = Array.isArray(this.provinces) ? this.provinces : Object.values(this.provinces);
                this.provinceinfo = provinces.filter(i => i.id == e)[0]
                getCpc({
                    pid: e
                }).then(res => {
                    this.citys = res.data
                })
            } else if (type == 'city') {
                const citys = Array.isArray(this.citys) ? this.citys : Object.values(this.citys);

                this.cityinfo = citys.filter(i => i.id == e)[0]
            }
        },
        //计算价格
        onCalculate() {
            let theBaby
            let theRaising
            let songRaising
            //举牌接机
            if (this.Raising) {
                theRaising = 2 //1不需要 2需要
                this.theRaising = 2
            } else {
                theRaising = 1
                this.theRaising = 1
            }
            //宝宝座椅
            if (this.theBaby) {
                theBaby = this.num
                this.theBabyNum = this.num
            } else {
                theBaby = 0
                this.theBabyNum = 0
            }
            //接机服务
            if (this.songServe) {
                songRaising = 2 //1不需要 2需要
                this.songRaising = 2
            } else {
                songRaising = 1
                this.songRaising = 1
            }
            const loading = this.$loading({
                lock: true,
                text: '价格计算中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            });
            calculateSingleCharterPrice({
                setOutAddress: this.info.start,
                appointmentTime: this.info.Stime,
                cardId: this.changeCarinfo.id,
                vehicleDuration: this.info.carTime,
                theRaising,
                theBaby,
                songRaising,
                cfGuo: this.countryinfo.name,
                cfSheng: this.provinceinfo.name,
                cfShi: this.cityinfo.name,
                cfGuoId: this.countryinfo.id,
                cfShengId: this.provinceinfo.id,
                cfShiId: this.cityinfo.id

            }).then(res => {
                if (res.code == 200) {
                    this.price = res.data.orderAllMoney
                    this.juli = res.data.juli
                    this.hour = res.data.hour
                    this.cost = res.data.cost
                    this.dialogVisible = true
                } else {
                    Message({
                        type: 'error',
                        message: res.msg
                    })
                }
                loading.close();

            })
        },
        onOrder() {
            let order = {
                orderSource: 'PC',
                orderTypeCode: 'SINGLE_CHARTER',
                setOutAddress: this.info.start,
                appointmentTime: this.info.Stime,
                cardId: this.changeCarinfo.id,
                allMoney: this.price,
                theRaising: this.theRaising,
                theBaby: this.theBabyNum,
                songRaising: this.songRaising,
                cfGuo: this.countryinfo.name,
                cfSheng: this.provinceinfo.name,
                cfShi: this.cityinfo.name,
                cfGuoId: this.countryinfo.id,
                cfShengId: this.provinceinfo.id,
                cfShiId: this.cityinfo.id
            }
            this.$router.push({
                name: 'qorder',
                query: {
                    obj: encodeURIComponent(JSON.stringify(order)),
                    type: 5,
                }
            })
        }

    }
}
</script>

<style lang="scss" scoped>
.car_img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    margin-top: 20px
}

.jia_text {
    color: #999999;
}

.jie_img {
    width: 14px;
    height: 14px;
    margin-left: 8px;
}

.flexs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexs1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flexs2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexs3 {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flexs4 {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: flex-end;
}

.tipes {
    margin: 0 0 14px 0;
    font-size: 14px;
    color: #FE4E00;
}

.box {
    margin-top: 20px;
    background: #fff;
    width: 100%;
    padding: 24px;
    border-radius: 12px;

    ::v-deep .el-input__inner {
        background-color: #F3F3F3;
    }

    ::v-deep .el-checkbox__label {
        font-size: 18px;
    }


    .title {
        font-size: 24px;
        line-height: 39px;
    }

    .input-box {
        padding: 16px 0 20px 0;

    }

    .inputs {
        width: 23%;
        display: flex;
        justify-content: space-between;
        padding: 6px 0 20px 0;

    }



    .btns {
        width: 300px;
    }


}

::v-deep .el-checkbox__label {
    font-size: 20px !important;
}

.tcBOX {
    text-align: center;

    .price {
        color: red;
        font-size: 30px;
        line-height: 60px;
    }

    .btns {
        width: 60%;
        margin: 0 auto;
    }
}
</style>