<template>
  <div style="height: 10px; width: 100%;"></div>
</template>

<script>
export default {

}
</script>

<style>

</style>