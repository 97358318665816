<template>
    <div class="vlogList">
        <vvlog-item :item="item" v-for="(item, index) in list" :key="index"></vvlog-item>
        <div class="paging">
            <el-pagination background layout="prev, pager, next" :total="count" :page-size="9"
                :page-count="Math.ceil(count / 3)" @current-change="currentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { listDmInvitations } from '@/api/vlog'
export default {
    name: 'more',
    data() {
        return {
            list: [],
            count: 1, //总数据数量
            pageNum: 1
        }
    },
    mounted() {
        this.$scrollTo()
    },
    created() {
        this.getList()
    },
    methods: {
        currentChange(e) {
            this.pageNum = e
            this.getList()
        },
        getList() {
            listDmInvitations(this.pageNum, 9).then(res => {
                this.list = res.rows
                this.count = res.total
                console.log(res)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.vlogList {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: calc((100% - 388px * 3)/2);

    .paging {
        padding-bottom: 30px;
        margin-left: 1150px;
    }

}
</style>