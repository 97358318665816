//vlog
import { $http } from '../plugins/axios'
const { post, get } = $http


//vlog列表
export const listDmInvitations = (pageNum, pageSize) => {
    return get(`fangshan/dm-user/listDmInvitations?pageNum=${pageNum}&pageSize=${pageSize}`)
}
//vlog详情
export const getDmInvitationById = (id) => {
    return get(`fangshan/dm-user/getDmInvitationById?id=${id}`)
}