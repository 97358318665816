//首页
import { $http } from '../plugins/axios'
const { post, get } = $http



//轮播图
export const selectBannerList = () => {
    return get('fangshan/dm-user/selectBannerList')
}
//热门目的地、旅游产品、车辆租赁列表
export const selectDmDestinationList = (pageNum, pageSize, type, desName, destinationTypeId) => {
    return get(
        `fangshan/dm-user/selectDmDestinationList?pageNum=${pageNum}&pageSize=${pageSize}&type=${type}&desName=${desName}&destinationTypeId=${destinationTypeId}`
    )
}
//热门目的地、旅游产品、车辆租赁详情
export const selectDmDestinationInfo = (destinationId) => {
    return get(
        `fangshan/dm-user/selectDmDestinationInfo?destinationId=${destinationId}`
    )
}
//新增需求定制
export const insertDmCustomized = (params) => {
    return post('fangshan/dm-user/insertDmCustomized', { ...params })
}
//定制提交历史
export const selectDmCustomizedList = () => {
    return get('fangshan/dm-user/selectDmCustomizedList')
}
//删除定制消息
export const deleteCustomized = (id) => {
    return post(`fangshan/dm-user/deleteCustomized?id=${id}`)
}

//旅游产品分类
export const listDestinationType = () => {
    return get('/fangshan/dm-user/listDestinationType')
}
//24.8.27--地址搜索范围中文
export const addressSearch = (address) => {
    return get(`/fangshan/dm-new/getAddressByGoogle?name=${address}`)
}
//24.8.27--地址搜索范围英文
export const addressSearchEnglish = (address) => {
    return get(`/fangshan/dm-new/getAddressByGoogleForEnglish?name=${address}`)
}