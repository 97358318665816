<template>
  <div class="swiper">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
    <div v-if="navigation" class="swiper-button-prev"></div>
    <div v-if="navigation" class="swiper-button-next"></div>
  </div>
</template>

<script>
export default {
  name: "VSwiper",
  props: {
    pagination: Boolean,
    navigation: Boolean,
    options: Object,
  },
  mounted() {
    this.$swiper(".swiper", {
      ...this.options,
    });
  },
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  color: rgb(77, 77, 77);
}
</style>
