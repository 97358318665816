<template>
    <div class="centers">
        <div class="list">
            <destination :item="item" v-for="item in list" :key="item.id" @click.native="onInfo(item.id)"></destination>
        </div>
        <p class="tips" v-if="!list.length">没有匹配项！</p>
        <div class="paging" v-else>
            <el-pagination background layout="prev, pager, next" :total="count" :page-size="8"
                :page-count="Math.ceil(count / 3)" @current-change="currentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { selectDmDestinationList } from '@/api/home'

export default {
    name: 'travelList',
    data() {
        return {
            list: [],
            count: 1, //总数据数量
            pageNum: 1
        }
    },
    created() {
        this.getList()
    },
    methods: {
        onInfo(id) {
            this.$router.push({ path: '/travelDetails', query: { id } })
        },
        getList() {
            selectDmDestinationList(this.pageNum, 8, 2, this.$route.query.name ? this.$route.query.name : '').then(res => {
                this.list = res.rows
                this.count = res.total
            })
        },
        currentChange(e) {
            this.pageNum = e
            this.getList()
        },
    }

}
</script>

<style lang="scss" scoped>
.tips {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
    color: #0D5E6B;
    margin-top: 100px;
}

.paging {
    padding-bottom: 30px;
    margin-left: 1100px;
}

.list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: calc((100% - 290px * 4) / 3);

}
</style>