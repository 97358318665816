<template>
  <div class="centers">
    <div class="list">
      <div class="item" v-for="item in list" :key="item.id" @click="onDetails(item.id)">
        <div class="top">
          <img :src="item.desImg" alt="" style="object-fit: cover;">
        </div>
        <div class="bottom">
          <div class="name">{{ item.desName }}</div>
          <div class="price">￥{{ item.desMoney }}/天</div>
        </div>
      </div>
    </div>
    <p class="tips" v-if="!list.length">没有匹配项！</p>
    <div class="paging" v-else>
      <el-pagination background layout="prev, pager, next" :total="count" :page-size="12"
        :page-count="Math.ceil(count / 3)" @current-change="currentChange">
      </el-pagination>
    </div>
  </div>
</template>
  
<script>
import { selectDmDestinationList } from '@/api/home'
export default {
  data() {
    return {
      list: [],
      count: 1, //总数据数量
      pageNum: 1
    }
  },
  created() {
    console.log(this.$route.query.name)
    this.getList()
  },
  methods: {
    onDetails(id) {
      this.$router.push({ path: '/carDetails', query: { id } })
    },
    getList() {
      selectDmDestinationList(this.pageNum, 12, 3, this.$route.query.name ? this.$route.query.name : '', '').then(res => {
        this.list = res.rows
        this.count = res.total
      })
    },
    currentChange(e) {
      this.pageNum = e
      this.getList()
    }
  }


}
</script>
  
<style lang="scss" scoped>
.tips {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  color: #0D5E6B;
  margin-top: 100px;
}

.paging {
  padding-bottom: 30px;
  float: right;
}

.list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 14px calc((100% - 291px * 4)/3);

  .item {
    width: 291px;
    height: 249px;
    background: #FFFFFF;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    user-select: none;


    img {
      height: 170px;
    }

    .bottom {
      padding: 16px 10px;

      .name {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 22px;

      }

      .price {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FF0909;
        line-height: 32px;
      }
    }
  }
}
</style>