import Vue from 'vue';

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"

const defaultOptions = {
    modules: [Navigation, Pagination, Autoplay],
    loop: true,
    autoplay: {
        delay: 2500,
    },
    pagination: {
        el: '.swiper-pagination',
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
};

Vue.prototype.$swiper = (dom, options = {}) => new Swiper(dom, { ...defaultOptions, ...options });
