<template>
    <div class="centers">
        <div class="box">
            <div class="input-box">
                <div class="title">目的地城市</div>
                <el-input v-model="info.city" placeholder="请输入目的地城市名称"></el-input>
            </div>
            <div class="input-box">
                <div class="title">预算（元）</div>
                <el-input v-model="info.budget" placeholder="请输请输入预算金额入内容"></el-input>
            </div>
            <div class="input-s">
                <div class="title">联系人信息</div>
                <div class="inpusb">
                    <el-input v-model="info.man" placeholder="请输入联系人姓名"></el-input>
                    <el-input v-model="info.phone" placeholder="请输入联系电话"></el-input>
                </div>
            </div>
            <div class="btn">
                <button class="submit-btn" :disabled="del" @click="onsubmit">提交</button>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <div class="top">
                    <div class="time">{{ item.createTime }}</div>
                    <div class="del" @click="dels(item.id)"> <i class="el-icon-delete" style="font-size: 18px;"></i></div>
                </div>
                <div class="info">目的地：{{ item.destinationCity }}</div>
                <div class="info">预算（元）：{{ item.budget }} </div>
                <div class="info">联系信息：{{ item.contactsName }} {{ item.contactsMobile }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { selectDmCustomizedList, insertDmCustomized, deleteCustomized } from '@/api/home'
export default {
    name: 'customization',
    data() {
        return {
            info: {
                city: '',
                budget: '',
                man: '',
                phone: '',
            },
            list: []
        }
    },
    computed: {
        del() {
            for (let key in this.info) {
                if (this.info[key] === '') {
                    return true;
                }
            }
            return false;
        }
    },
    created() {
        this.getList()
    },
    methods: {
        dels(id) {
            deleteCustomized(id).then(res => {
                if (res.code === 200) {
                    this.getList()
                    this.$message.success(res.msg)
                } else {
                    this.$message.warning(res.msg)

                }
            })
        },
        getList() {
            selectDmCustomizedList().then(res => {
                this.list = res.rows
            })
        },
        onsubmit() {
            insertDmCustomized({
                destinationCity: this.info.city,
                budget: this.info.budget,
                contactsName: this.info.man,
                contactsMobile: this.info.phone
            }).then(res => {
                if (res.code === 200) {
                    this.getList()
                    this.$message.success('提交成功')
                } else {
                    this.$message.warning(res.msg)

                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.del {
    cursor: pointer;
    user-select: none;
}

.box {
    margin-top: 20px;
    background: #fff;
    width: 100%;
    padding: 24px;
    border-radius: 12px;

    ::v-deep .el-input__inner {
        background-color: #F3F3F3;
    }

    .input-box {
        width: 300px;
        padding: 10px 0;
        // border: 1px solid #000;
        margin-bottom: 20px;



        .title {
            font-size: 20px;
            font-weight: 400;
            color: #000000;
            margin-bottom: 14px;
        }

    }

    .input-s {
        .title {
            font-size: 20px;
            font-weight: 400;
            color: #000000;
            margin-bottom: 14px;
        }

        .inpusb {
            width: 640px;
            display: flex;
            // border: 1px solid #000;
            gap: 40px;
        }
    }

    .btn {
        margin: 30px 0;
        width: 300px;
    }
}

.list {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 30px;

    .item {
        width: calc(100% / 3 - 20px);
        background: #fff;
        padding: 18px 20px;
        border-radius: 12px;

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .time {
                font-size: 14px;
                font-family: Microsoft YaHei;
                color: #8A8A8A;
                line-height: 25px;
            }
        }

        .info {
            font-size: 14px;
            color: #000000;
            line-height: 25px;
        }
    }
}
</style>