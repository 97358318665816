<template>
    <div class="item">
        <div class="img-box">
            <img :src="item.desImg" style="object-fit: cover; width: 100%; height: 100%;">
        </div>
        <div class="info">
            <p class="infot">{{ item.desName }}</p>
            <div class="price">￥{{ item.desMoney }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'destination',
    props: {
        item: {
            type: Object,
            default: 0
        },
    },
}
</script>

<style lang="scss" scoped>
.item {
    margin: 0 0 20px 0;
    width: 290px;
    height: 260px;
    background: #FFFFFF;
    border-radius: 12px;
    cursor: pointer;
    user-select: none;

    .img-box {
        height: 160px;

        img {
            border-radius: 12px 12px 0 0;
        }
    }

    .info {
        padding: 14px;

        .infot {
            font-size: 15px;
            font-weight: 400;
            color: #000000;
            line-height: 21px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .price {

            font-size: 16px;
            font-weight: 500;
            color: #FF0909;
            line-height: 44px;
        }
    }
}
</style>