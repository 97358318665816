<template>
  <div>
    <div class="head">
      <div class="centers" style="display: flex;align-items: center; justify-content: space-between;">
        <div class="userInfo">
          <div class="header">
            <img :src="userInfo.avatar" width="100%" height="100%">
            <div class="set" @click="onSet">编辑资料</div>
          </div>
          <div class="info">
            <p class="nickname">{{userInfo.nickname}}</p>
            <p class="phone">{{userInfo.mobile}}</p>
            <p class="member">{{userInfo.userGrade}}</p>
          </div>
        </div>
        <div class="btn">
          <button>在线客服</button>
          <!-- <button style="margin-left: 20px;" @click="navBar(9, '/centre/message')" :class="{ 'active': $route.path == '/centre/message' }">系统消息</button> -->
        </div>
      </div>
    </div>
    <v-gap></v-gap>
    <div class="centers" style="display: flex; gap: 10px;">
      <v-sidebar></v-sidebar>
      <div style="width: 100%;">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
  
<script>
import { getHighImportInfo } from '@/api/mine'
export default {
  name: 'centre',
  data() {
    return {
      userInfo: {}
    }
  },
  created() {
    getHighImportInfo().then(res => {
      this.userInfo = res.data
    })
  },
  methods: {
	navBar(id, path) {
	    this.active = id;
	    sessionStorage.setItem('ACTIVE_MENU_PERSON', id);
	    this.$router.push(path, () => { }, () => { })
	},
    onSet() {
      if (this.$route.path == '/centre/setMessage') {
        return
      }
      this.$router.push('/centre/setMessage')
    }
  }

}
</script>
  
<style scoped lang="scss">
.head {
  width: 100%;
  height: 210px;
  background: #e7eced;
  padding-top: 50px;


  .userInfo {
    display: flex;
    align-items: center;
    gap: 20px;

    .header {
      width: 110px;
      height: 110px;
      position: relative;
      border-radius: 50%;
      overflow: hidden;

      .set {
        position: absolute;
        top: 69px;
        background: rgba($color: #000000, $alpha: 0.52);
        font-size: 17px;
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        width: 110px;
        height: 40px;
        padding: 10px;
        cursor: pointer;
        user-select: none;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .nickname {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
      }

      .phone {
        font-size: 15px;
        font-weight: 400;
        color: #727272;
      }

      .member {
        font-size: 15px;
        font-weight: 400;
        color: #000000;
      }
    }
  }

  .btn {
    button {
      width: 184px;
      height: 52px;
      background: #0D5E6B;
      color: #fff;
      font-size: 18px;
    }
  }
}
</style>